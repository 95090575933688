import React, { FC } from 'react';

import { DeleteIcon } from 'applicaiton/assets';

import { TableRowProps } from './models';
import { StyledTr, ActionsStyled, Phone } from './styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const TableRow: FC<TableRowProps> = ({ item, onDelete, isProfessional }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledTr>
      <td>
        <span>
          {dayjs(item?.createdAt).locale(i18n.language).format('DD MMMM, YYYY')}{' '}
          {t('at')}{' '}
          {dayjs(item?.createdAt).locale(i18n.language).format('HH:mm')}
        </span>
      </td>
      {!isProfessional && (
        <td>
          <span>
            {item.professional?.firstName} {item.professional?.lastName}
          </span>
        </td>
      )}
      <td>
        <span>
          {item.patient?.firstName} {item.patient?.lastName}
        </span>
      </td>
      <td className="phone-column">
        <Phone href={`tel:${item.patient?.phone}`}>{item.patient?.phone}</Phone>
      </td>
      <td>
        <ActionsStyled>
          <button
            type="button"
            onClick={() => {
              onDelete(item);
            }}
          >
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default TableRow;
