import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ClinicOwnerUpdatePurchasedSubscriptionDto } from '@docbay/schemas';

import { ClinicOwnerSubscriptionActions } from 'applicaiton/constants/actions';

import { ClinicOwnerSubscriptionAPI } from 'integration/api/clinicOwnerSubscription';

export const getCurrentSubscription = createAsyncThunk(
  ClinicOwnerSubscriptionActions.getCurrentSubscription,
  async (_, { rejectWithValue }) => {
    try {
      const { data } =
        await ClinicOwnerSubscriptionAPI.getCurrentSubscription();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateCurrentSubscription = createAsyncThunk(
  ClinicOwnerSubscriptionActions.updateCurrentSubscription,
  async (
    params: ClinicOwnerUpdatePurchasedSubscriptionDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } =
        await ClinicOwnerSubscriptionAPI.updateCurrentSubscription(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getPaymentMethods = createAsyncThunk(
  ClinicOwnerSubscriptionActions.getPaymentMethods,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerSubscriptionAPI.getPaymentMethods();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const setDefaultPaymentMethod = createAsyncThunk(
  ClinicOwnerSubscriptionActions.setDefaultPaymentMethod,
  async (paymentMethodId: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerSubscriptionAPI.setDefaultPaymentMethod(
        paymentMethodId,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
