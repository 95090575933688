import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    text-align: start;
    vertical-align: middle;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    span {
      display: block;
    }

    &.phone-column {
      width: 200px;
    }

    &:last-of-type {
      width: 140px;
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.blue800};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
    text-transform: unset;

    > svg {
      margin-right: 4px;

      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const Phone = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.grey700};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;
