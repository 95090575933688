import React, { FC } from 'react';

import { Header, BillingsList } from './components';
import { Wrapper } from './styles';

const ClinicBilling: FC = () => {
  return (
    <Wrapper>
      <Header />
      <BillingsList />
    </Wrapper>
  );
};

export default ClinicBilling;
