import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';

export const shouldPay = () => {
  const currentClinicOwnerRegistrationData = currentClinicOwnerRegistration();

  const invoicingSubscriptionPrice =
    currentClinicOwnerRegistrationData?.subscriptionInvoicingPackages?.prices?.find(
      (item) =>
        currentClinicOwnerRegistrationData.isYearlyPeriod
          ? item.recurringInterval === 'year'
          : item.recurringInterval === 'month',
    )?.unitAmountDecimal || 0;

  const subscriptionPackageKey =
    currentClinicOwnerRegistrationData?.subscriptionPackages
      ? Object.keys(currentClinicOwnerRegistrationData?.subscriptionPackages)[0]
      : null;
  const subscriptionPackagesPrice = subscriptionPackageKey
    ? currentClinicOwnerRegistrationData.subscriptionPackages[
        subscriptionPackageKey
      ][0]?.prices?.find((item) =>
        currentClinicOwnerRegistrationData.isYearlyPeriod
          ? item.recurringInterval === 'year'
          : item.recurringInterval === 'month',
      )?.unitAmountDecimal || 0
    : 0;

  const currentPrice = subscriptionPackageKey
    ? currentClinicOwnerRegistrationData.subscriptionPackages[
        subscriptionPackageKey
      ][0]?.prices?.find((item) =>
        currentClinicOwnerRegistrationData.isYearlyPeriod
          ? item.recurringInterval === 'year'
          : item.recurringInterval === 'month',
      )
    : null;

  const currentTier =
    currentPrice && currentPrice?.tiers ? currentPrice?.tiers[0] : null;

  const tierPrice = currentTier ? currentTier?.unitAmountDecimal : 0;

  return (
    Number(invoicingSubscriptionPrice) > 0 ||
    Number(subscriptionPackagesPrice) > 0 ||
    Number(tierPrice) > 0
  );
};
