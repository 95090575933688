import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

export const BillingInfoStyled = styled.article`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;

  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > section {
    display: flex;
    flex-direction: column;
    max-width: 470px;

    > h2 {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 24px;
    }
    > div {
      display: grid;
      gap: 16px;
    }
  }
`;

export const IbanSection = styled.section`
  display: grid;
  gap: 6px;
  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.color.grey600};
  }
`;

export const IbanStyled = styled.div`
  display: grid;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grey300};
  padding: 10px 14px;
  width: 100%;
  height: 44px;
  box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
`;
export const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',

    fontFamily: 'Mulish, sans-serif',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
};
