import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BillingInfo, InitialState } from './models';
import {
  getCurrentSubscription,
  getPaymentMethods,
  setDefaultPaymentMethod,
  updateCurrentSubscription,
} from './ActionCreators';
import { SubscriptionPackageResponse } from '@docbay/schemas';

const initialState: InitialState = {
  isLoading: false,
  error: '',
  subscriptionPackage: null,
  professionalsCount: 0,
  featurePackages: [],
  selectedPackages: [],
  selectedInvoicingPackage: null,
  isYearlySubscription: false,
  billingInfo: null,
  paymentMethods: [],
};

const clinicOwnerSubscriptionSlice = createSlice({
  name: 'clinicOwnerSubscriptionSlice',
  initialState,
  reducers: {
    setSelectedPackagesData: (
      state,
      action: PayloadAction<{
        selectedPackages: SubscriptionPackageResponse[];
        selectedInvoicingPackage: SubscriptionPackageResponse | null;
        isYearlySubscription: boolean;
      }>,
    ) => {
      state.selectedPackages = action.payload.selectedPackages;
      state.selectedInvoicingPackage = action.payload.selectedInvoicingPackage;
      state.isYearlySubscription = action.payload.isYearlySubscription;
    },
    setBillingInfo: (state, action: PayloadAction<BillingInfo | null>) => {
      state.billingInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get current subscription
    builder.addCase(getCurrentSubscription.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getCurrentSubscription.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getCurrentSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.subscriptionPackage = action.payload.subscriptionPackage;
      state.professionalsCount = action.payload.professionalsCount || 0;
      state.featurePackages = action.payload.featurePackages;
    });
    //update current subscription
    builder.addCase(updateCurrentSubscription.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updateCurrentSubscription.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateCurrentSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.subscriptionPackage = action.payload.subscriptionPackage;
      state.professionalsCount = action.payload.professionalsCount || 0;
      state.featurePackages = action.payload.featurePackages;
    });
    //get payment methods
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.paymentMethods = action.payload;
    });
    //set default payment method
    builder.addCase(setDefaultPaymentMethod.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(setDefaultPaymentMethod.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
    });
  },
});

export const { setSelectedPackagesData, setBillingInfo } =
  clinicOwnerSubscriptionSlice.actions;
export default clinicOwnerSubscriptionSlice.reducer;
