import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { AsideStyled, StyledItem } from './styles';
import { Divider } from '@mui/material';
import { SideBarProps } from './modules';
import { useFormContext } from 'react-hook-form';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import LeavePageModal from '../LeavePageModal';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

const SideBar: FC<SideBarProps> = ({
  clinics,
  handleAddClinic,
  selectedClinic,
  setSelectedClinic,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();
  const dispatch = useAppDispatch();
  const { isPrivateDoctor } = useClinicOwner();
  const userRole = getUserRole();
  const isProfessionalRole = userRole === UserRoles.professional;
  const selectedClinicIndex = isProfessionalRole ? 5 : 2;
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const { editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const [showModal, setShowModal] = useState(false);
  const [nextFormIndex, setNextFormIndex] = useState<number | null>(null);

  const canManageSubscription = useMemo(() => {
    const hasPermission = editedProfessional?.clinicsRelations?.some((item) => {
      const { hasDocboxSubscriptionConfigurationPermission } =
        featuresPermissions(item.clinic?.owner?.id);
      return hasDocboxSubscriptionConfigurationPermission;
    });

    return hasPermission;
  }, [editedProfessional?.clinicsRelations]);

  const handleChangeForm = (index: number) => {
    if (selectedClinic === index) return;
    if (hasUnsavedChanges) {
      setShowModal(true);
      setNextFormIndex(index);
    } else {
      setSelectedClinic(index);
      setNextFormIndex(null);
    }
  };

  const handleCloseModal = () => {
    setSelectedClinic(Number(nextFormIndex));
    setShowModal(false);
    dispatch(setHasUnsavedChanges(false));
  };

  const showAddClinic = !isPrivateDoctor && !isProfessionalRole;

  return (
    <AsideStyled>
      <LeavePageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleCloseModal}
      />
      <StyledItem
        isSelected={!selectedClinic}
        onClick={() => handleChangeForm(0)}
      >
        {t('professionalConfiguration.general_profile_info')}
      </StyledItem>
      {canManageSubscription && (
        <StyledItem
          className={'clinic'}
          isSelected={selectedClinic === 1}
          onClick={() => {
            handleChangeForm(1);
          }}
        >
          {t('professional_profile.subscriptions.subscription_title')}
        </StyledItem>
      )}
      <div>
        <Divider />
        {isProfessionalRole && (
          <>
            <StyledItem
              isSelected={selectedClinic === 2}
              onClick={() => handleChangeForm(2)}
            >
              {t('email')}
            </StyledItem>
            <StyledItem
              isSelected={selectedClinic === 3}
              onClick={() => handleChangeForm(3)}
            >
              {t('phone')}
            </StyledItem>
            <StyledItem
              isSelected={selectedClinic === 4}
              onClick={() => handleChangeForm(4)}
            >
              {t('password')}
            </StyledItem>
          </>
        )}

        {clinics.map((clinic, index) => (
          <StyledItem
            className={'clinic'}
            key={index}
            isSelected={selectedClinic === index + selectedClinicIndex}
            onClick={() => handleChangeForm(index + selectedClinicIndex)}
          >
            {clinic}
          </StyledItem>
        ))}
      </div>
      {showAddClinic && (
        <button
          type="button"
          onClick={
            !selectedClinic ? handleAddClinic : handleSubmit(handleAddClinic)
          }
        >
          <AddIcon />
          <h2>{t('add_another')}</h2>
        </button>
      )}
    </AsideStyled>
  );
};

export default SideBar;
