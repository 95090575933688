import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import {
  Wrapper,
  StyledDivider,
  ButtonsWrapper,
  SubscriptionSection,
  AddSubscriptionButton,
  EmptyState,
  TabsSection,
  TabItem,
} from './styles';
import { EmptySubscriptionSection } from 'features/feature-professional-add/components';
import AddSubscriptionModal from '../AddSubscriptionModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import SubscriptionItem from '../SubscriptionItem';
import {
  fetchProfessionalById,
  updateProfessional,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import {
  ClinicProfessionalUpdateDto,
  CpSubscriptionCreationDto,
  CpSubscriptionUpdateDto,
} from '@docbay/schemas';
import { EditSubscription } from 'applicaiton/store/reducers/Professionals/models';
import { v4 as uuid } from 'uuid';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { setProfileSubscriptions } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getCustomerBillingLink } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { EmptyStateIcon } from 'applicaiton/assets';
import { ClinicProfessionalSubscriptionType } from 'common/types/subscriptions';

const ProfessionalSubscriptions: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userRole = getUserRole();
  const isProfessionalRole = userRole === UserRoles.professional;
  const [isOpenAddSubscription, setIsOpenAddSubscription] = useState(false);
  const [currentTab, setCurrentTab] =
    useState<ClinicProfessionalSubscriptionType | null>(null);
  const { updatePaymentAccountStatus, isPaymentAccountActive, isLoading } =
    useClinicOwner();

  const {
    profileChanges,
    editedProfessional,
    subscriptions,
    isLoading: isPatientLoading,
    clinicOwnerIdsWithNotConfiguredPayment,
  } = useAppSelector((state) => state.professionalProfile);

  useEffect(() => {
    if (subscriptions.length && !currentTab) {
      const hasCompanySubscriptions = subscriptions.some(
        (item) =>
          String(item.type) === ClinicProfessionalSubscriptionType.COMPANY,
      );
      setCurrentTab(
        hasCompanySubscriptions
          ? ClinicProfessionalSubscriptionType.COMPANY
          : ClinicProfessionalSubscriptionType.PUBLIC,
      );
    }
  }, [subscriptions, currentTab]);

  const filteredSubscriptions = useMemo(() => {
    return subscriptions.filter((item) => String(item.type) === currentTab);
  }, [currentTab, subscriptions]);

  useEffect(() => {
    if (isProfessionalRole) {
      editedProfessional?.clinicOwnerIds?.map(async (id) => {
        await dispatch(getCustomerBillingLink(id!)).then((data) => {
          if (data.meta.requestStatus === 'fulfilled') {
            if (!data.payload) {
              dispatch(
                professionalProfileActions.setClinicOwnerWithoutAccount([
                  ...clinicOwnerIdsWithNotConfiguredPayment,
                  id,
                ]),
              );
            }
          }
        });
      });
    } else {
      updatePaymentAccountStatus();
    }
  }, [editedProfessional]);

  useEffect(() => {
    const arr: any = [];

    editedProfessional?.clinicsRelations?.map((clinic) => {
      if (clinic.subscriptions?.length) {
        clinic.subscriptions?.map((subscription) => {
          arr.push({
            id: subscription?.id ? subscription?.id : uuid(),
            clinicId: clinic!.clinic!.id!,
            title: subscription.title,
            price: subscription.price!,
            durationInWeeks: subscription.durationInWeeks!,
            teleconsultationMinutes: subscription.teleconsultationMinutes!,
            onlineHours: subscription.onlineHours,
            type: subscription.type as ClinicProfessionalSubscriptionType,
            minNumberOfEmployees: subscription.minNumberOfEmployees,
            maxNumberOfEmployees: subscription.maxNumberOfEmployees,
          });
        });
      }
    });

    dispatch(professionalProfileActions.setProfileSubscriptions(arr));
  }, [editedProfessional]);

  const setSubscriptionIntoClinic = (data: EditSubscription) => {
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    if (profileChanges?.clinicsRelations?.length) {
      profileChanges?.clinicsRelations?.map((clinic) => {
        if (clinic!.clinicId === data.clinicId) {
          const newData: CpSubscriptionCreationDto = {
            title: data.title!,
            price: data.price!,
            durationInWeeks: data.durationInWeeks!,
            teleconsultationMinutes: data.teleconsultationMinutes!,
            onlineHours: data.onlineHours,
            type: data.type as ClinicProfessionalSubscriptionType,
            minNumberOfEmployees: data.minNumberOfEmployees,
            maxNumberOfEmployees: data.maxNumberOfEmployees,
          };

          newClinicsRelations.push({
            clinicId: data.clinicId!,
            subscriptionsToAdd: [
              ...((clinic.subscriptionsToAdd as CpSubscriptionCreationDto[]) ||
                []),
              newData,
            ],
            ...(clinic.subscriptionsToUpdate
              ? { subscriptionsToUpdate: clinic.subscriptionsToUpdate }
              : []),
            ...(clinic.subscriptionsToDelete
              ? { subscriptionsToDelete: clinic.subscriptionsToDelete }
              : []),
          });
        } else {
          const newData: CpSubscriptionCreationDto = {
            title: data.title!,
            price: data.price!,
            durationInWeeks: data.durationInWeeks!,
            teleconsultationMinutes: data.teleconsultationMinutes!,
            onlineHours: data.onlineHours,
            type: data.type as ClinicProfessionalSubscriptionType,
            minNumberOfEmployees: data.minNumberOfEmployees,
            maxNumberOfEmployees: data.maxNumberOfEmployees,
          };

          newClinicsRelations.push({
            clinicId: data.clinicId!,
            subscriptionsToAdd: [newData],
          });
        }
      });
    } else {
      newClinicsRelations.push({
        clinicId: data.clinicId!,
        subscriptionsToAdd: [
          {
            title: data.title!,
            price: data.price!,
            durationInWeeks: data.durationInWeeks!,
            teleconsultationMinutes: data.teleconsultationMinutes!,
            onlineHours: data.onlineHours,
            type: data.type as ClinicProfessionalSubscriptionType,
            minNumberOfEmployees: data.minNumberOfEmployees,
            maxNumberOfEmployees: data.maxNumberOfEmployees,
          },
        ],
      });
    }

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    dispatch(
      professionalProfileActions.setProfileSubscriptions([
        ...subscriptions,
        {
          id: uuid(),
          clinicId: data.clinicId,
          title: data.title,
          price: data.price!,
          durationInWeeks: data.durationInWeeks!,
          teleconsultationMinutes: data.teleconsultationMinutes!,
          onlineHours: data.onlineHours,
          type: data.type as ClinicProfessionalSubscriptionType,
          minNumberOfEmployees: data.minNumberOfEmployees,
          maxNumberOfEmployees: data.maxNumberOfEmployees,
        },
      ]),
    );

    dispatch(professionalProfileActions.setProfileChanges(prof!));
    setIsOpenAddSubscription(false);
  };

  const isSaveButtonDisabled = useMemo(() => {
    if (!profileChanges?.clinicsRelations) return true;

    const arr: any = [];
    profileChanges?.clinicsRelations.map((item) => {
      arr.push(item.subscriptionsToAdd || []);
      arr.push(item.subscriptionsToDelete || []);
      arr.push(item.subscriptionsToUpdate || []);
    });

    return !arr.flat().length;
  }, [profileChanges?.clinicsRelations, subscriptions]);

  const onSubmit = async () => {
    const responseUpdateProfessional = await dispatch(
      updateProfessional({
        id: editedProfessional?.id! || '',
        professional: { clinicsRelations: profileChanges?.clinicsRelations! },
      }),
    );
    if (responseUpdateProfessional.meta.requestStatus === 'fulfilled') {
      await dispatch(setProfileSubscriptions([]));
      await dispatch(professionalProfileActions.setProfileChanges(null));
      await dispatch(fetchProfessionalById(String(editedProfessional?.id!)));
      dispatch(setHasUnsavedChanges(false));
      setCurrentTab(null);
    }
  };

  const handleOpenAddSubscription = () => {
    setIsOpenAddSubscription(true);
  };

  const handleCloseAddSubscription = () => {
    setIsOpenAddSubscription(false);
  };

  const handleDelete = (data: EditSubscription) => {
    const indexOfObject = subscriptions!.findIndex(
      (object) => object.id === data.id,
    );

    const newSubscriptions = [...subscriptions];
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    newSubscriptions!.splice(indexOfObject, 1);

    const subs = newSubscriptions.filter(
      (item) => item.clinicId === data.clinicId && item?.id?.includes('-'),
    );

    const newSubs = subs.map((item) => {
      return {
        title: item.title!,
        price: Number(item.price)!,
        durationInWeeks: item.durationInWeeks!,
        teleconsultationMinutes: item.teleconsultationMinutes!,
        onlineHours: item.onlineHours,
        type: item.type,
        minNumberOfEmployees: item.minNumberOfEmployees,
        maxNumberOfEmployees: item.maxNumberOfEmployees,
      };
    });

    if (profileChanges?.clinicsRelations?.length) {
      profileChanges?.clinicsRelations?.map((clinic) => {
        if (clinic.clinicId === data.clinicId) {
          newClinicsRelations.push({
            ...clinic,
            subscriptionsToAdd: newSubs,
            subscriptionsToDelete: !data.id?.includes('-')
              ? ([
                  ...(clinic!.subscriptionsToDelete || []),
                  { id: data!.id! },
                ] as CpSubscriptionUpdateDto[])
              : [],
            ...(clinic.subscriptionsToUpdate
              ? clinic.subscriptionsToUpdate
              : []),
          });
        } else {
          newClinicsRelations.push(clinic);
        }
      });
    } else {
      newClinicsRelations.push({
        clinicId: data.clinicId!,
        subscriptionsToDelete: [
          {
            id: data.id,
          },
        ],
      });
    }

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };
    dispatch(
      professionalProfileActions.setProfileSubscriptions(newSubscriptions),
    );
    dispatch(professionalProfileActions.setProfileChanges(prof!));
  };

  const isFilteredListEmpty = useMemo(() => {
    return !!subscriptions.length && !filteredSubscriptions.length;
  }, [subscriptions, filteredSubscriptions]);

  const isShowEmptyState = useMemo(() => {
    if (isProfessionalRole) {
      return (
        ((!clinicOwnerIdsWithNotConfiguredPayment.length ||
          clinicOwnerIdsWithNotConfiguredPayment.length !==
            editedProfessional?.clinicsRelations?.length) &&
          !subscriptions.length) ||
        isFilteredListEmpty
      );
    }

    return (
      (isPaymentAccountActive && !subscriptions.length) || isFilteredListEmpty
    );
  }, [
    isPaymentAccountActive,
    clinicOwnerIdsWithNotConfiguredPayment,
    editedProfessional?.clinicsRelations,
    subscriptions,
    isFilteredListEmpty,
  ]);

  const isShowEmptySubscriptionSection = useMemo(() => {
    if (isProfessionalRole) {
      return !!clinicOwnerIdsWithNotConfiguredPayment.length;
    }

    return !isPaymentAccountActive;
  }, [clinicOwnerIdsWithNotConfiguredPayment, isPaymentAccountActive]);

  return (
    <Wrapper>
      {isPatientLoading && <Loader />}
      {isShowEmptySubscriptionSection && (
        <EmptySubscriptionSection
          onRefreshPaymentAccountStatus={updatePaymentAccountStatus}
        />
      )}
      {!!subscriptions.length && (
        <SubscriptionSection>
          <p>{t('professional_profile.subscriptions.subscription_list')}</p>
          <TabsSection>
            <TabItem
              active={currentTab === ClinicProfessionalSubscriptionType.PUBLIC}
              onClick={() =>
                setCurrentTab(ClinicProfessionalSubscriptionType.PUBLIC)
              }
            >
              {t('subscriptions.public_subscriptions')}
            </TabItem>
            <TabItem
              active={currentTab === ClinicProfessionalSubscriptionType.COMPANY}
              onClick={() =>
                setCurrentTab(ClinicProfessionalSubscriptionType.COMPANY)
              }
            >
              {t('subscriptions.company_subscriptions')}
            </TabItem>
          </TabsSection>
          {filteredSubscriptions?.map((item, index) => {
            return (
              <SubscriptionItem
                key={index}
                data={item}
                onDelete={handleDelete}
              />
            );
          })}
        </SubscriptionSection>
      )}
      {isShowEmptyState && (
        <EmptyState
          className={isFilteredListEmpty ? 'filtered-list-empty' : ''}
        >
          <EmptyStateIcon />
          <p>{t('professional_profile.subscriptions.empty')}</p>
          <SecondaryButton onClick={handleOpenAddSubscription}>
            <AddIcon />
            {t('professional_profile.subscriptions.add_subscription')}
          </SecondaryButton>
        </EmptyState>
      )}
      {!!subscriptions.length && !isFilteredListEmpty && (
        <AddSubscriptionButton>
          <SecondaryButton onClick={handleOpenAddSubscription}>
            <AddIcon />
            {t('professional_profile.subscriptions.add_subscription')}
          </SecondaryButton>
        </AddSubscriptionButton>
      )}

      <StyledDivider />
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={onSubmit}
          disabled={isSaveButtonDisabled}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>
      {isOpenAddSubscription && (
        <AddSubscriptionModal
          isOpen={isOpenAddSubscription}
          onClose={handleCloseAddSubscription}
          onAdd={setSubscriptionIntoClinic}
          type={currentTab}
        />
      )}
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default ProfessionalSubscriptions;
