import React, { FC, useMemo, useState } from 'react';
import {
  Wrapper,
  Subscription,
  WorkingHours,
  WorkingSchedule,
  WorkingDays,
} from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { useTranslation } from 'react-i18next';
import { CreateSubscription } from 'applicaiton/store/reducers/Professionals/models';
import AddSubscriptionModal from '../AddSubscriptionModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ClinicProfessionalUpdateDto } from '@docbay/schemas';
import { setProfileChanges } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import dayjs from 'dayjs';
import { ClinicProfessionalSubscriptionType } from 'common/types/subscriptions';

interface Props {
  data: CreateSubscription;
  onDelete: (data: CreateSubscription) => void;
}
const SubscriptionItem: FC<Props> = ({ data, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const { profileChanges, subscriptions } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );
  const { clinics } = useAppSelector((state) => state.clinics);

  const isCompanySubscription =
    data?.type === ClinicProfessionalSubscriptionType.COMPANY;

  const currentClinic = useMemo(() => {
    return clinics?.find((item) => item.id === data.clinicId);
  }, [clinics]);

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const handleOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleEditSubscription = (data: CreateSubscription) => {
    const indexOfObject = subscriptions!.findIndex(
      (object) => object.id === data.id,
    );

    const newSubscriptions = [...subscriptions];
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    newSubscriptions!.splice(indexOfObject, 1, data);

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.clinicId === data.clinicId) {
        const subs = newSubscriptions.filter(
          (item) => item.clinicId === data.clinicId,
        );

        newClinicsRelations.push({
          ...clinic,
          subscriptionsToAdd: subs,
        });
      } else {
        newClinicsRelations.push(clinic);
      }
    });

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    dispatch(setProfileChanges(prof!));
    handleCloseEditModal();
  };

  const durationText = useMemo(() => {
    const duration = Number(data?.durationInWeeks) || 0;
    const durationNumber = duration > 3 ? duration / 4 : duration;

    const durationName =
      duration > 3
        ? t(
            `professional_profile.subscriptions.${
              duration / 4 === 1 ? 'month' : 'months'
            }`,
          )
        : t(
            `professional_profile.subscriptions.${
              duration === 1 ? 'week' : 'weeks'
            }`,
          );

    return `${durationNumber} ${durationName}`;
  }, [data, t]);

  const handleDelete = () => {
    onDelete(data);
  };
  return (
    <Subscription>
      <Wrapper onClick={handleOpenEditModal}>
        <p>{data.title}</p>
        <p>
          {t('clinic')}:<span>{currentClinic?.name}</span>
        </p>
        {isCompanySubscription && (
          <p>
            {t('professional_profile.subscriptions.employee_range')}:
            <span>
              {`${data.minNumberOfEmployees} - ${data.maxNumberOfEmployees} ${t(
                'professional_profile.subscriptions.employees',
              )}`}
            </span>
          </p>
        )}
        <p>
          {t(
            isCompanySubscription
              ? 'professional_profile.subscriptions.price_label'
              : 'price',
          )}
          :<span>€{data.price}</span>
        </p>
        <p>
          {t('professional_profile.subscriptions.duration')}:
          <span>{durationText}</span>
        </p>
        <p>
          {t('professional_profile.subscriptions.teleconsultation')}:
          <span>
            {data.teleconsultationMinutes} {t('mins')}
          </span>
        </p>
        {!!data?.onlineHours?.length && (
          <WorkingSchedule>
            <h2>{t('professional_profile.subscriptions.online_hours_for')}</h2>
            <WorkingDays>
              {data.onlineHours!.map((item) => (
                <div key={item.day}>
                  <p>{t(item.day.toLowerCase())}</p>
                  <WorkingHours>
                    {item.slots.map((hour, index) => (
                      <p key={index}>
                        {dayjs(hour.startTime, 'HH:mm').format('HH:mm')} -{' '}
                        {dayjs(hour.endTime, 'HH:mm').format('HH:mm')}
                      </p>
                    ))}
                  </WorkingHours>
                </div>
              ))}
            </WorkingDays>
          </WorkingSchedule>
        )}
      </Wrapper>
      <CloseIcon onClick={handleDelete} />
      <AddSubscriptionModal
        key={'edit'}
        isOpen={isOpenEditModal}
        onClose={handleCloseEditModal}
        onAdd={handleEditSubscription}
        data={data}
        type={data.type}
      />
    </Subscription>
  );
};

export default SubscriptionItem;
