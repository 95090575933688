import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as UnreadChatsIcon } from 'applicaiton/assets/chats/unread-chats.svg';
import { ReactComponent as TeleconsultationChatsIcon } from 'applicaiton/assets/chats/teleconsultation-chats.svg';

import Switch from 'common/components/Switch';
import { useAppSelector } from 'common/hooks/redux';
import { SecondaryButton } from 'common/components';

import { PackFeatures } from 'features/feature-clinic-registration/constants';
import { getCurrentPeriodPrice } from 'features/feature-manage-plan/helpers/getCurrentPeriodPrice';

import Features from '../Features';
import PayPeriodSwitcher from '../PayPeriodSwitcher';

import { OnlinePackageProps } from './models';
import {
  Wrapper,
  AdditionalPrice,
  DividerStyled,
  OnlinePackageDescription,
  OnlinePackageInfo,
  OnlinePackageTax,
  OnlinePackageTitle,
  PeriodName,
  PriceSwitcher,
  Switcher,
  SwitchersSection,
  Content,
  MessageStyled,
  BottomSection,
} from './styles';

const OnlinePackage: FC<OnlinePackageProps> = ({
  isYearly,
  isInvoicing,
  onChange,
  onChangeInvoicing,
  onSubmit,
  isPlanChanged,
}) => {
  const { t } = useTranslation();

  const { subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const { subscriptionPackage } = useAppSelector(
    (state) => state.clinicOwnerSubscription,
  );

  const isCurrentPlanOnlineService =
    String(subscriptionPackage?.subscriptionType) ===
      PackFeatures.privateDoctorPack || !subscriptionPackage;

  const invoicingSubscriptionPrices = useMemo(() => {
    const invoicingSubscription = subscriptions?.find(
      (item) => String(item.type) === PackFeatures.invoicingFeature,
    );
    const price = getCurrentPeriodPrice(
      invoicingSubscription?.prices || [],
      isYearly,
    );
    return price?.unitAmountDecimal || 0;
  }, [subscriptions, isYearly]);

  const perPeriod = useMemo(() => {
    return isYearly ? t('per_year') : t('per_month');
  }, [isYearly]);

  return (
    <Wrapper>
      <Content>
        <OnlinePackageInfo>
          <OnlinePackageTitle>
            <UnreadChatsIcon />
            {t('clinicsConfiguration.aside_menu.doc_box')}
          </OnlinePackageTitle>
          <OnlinePackageDescription>
            {t('percent_of_subscription')}
          </OnlinePackageDescription>
          <OnlinePackageTax> {t('percentage')}</OnlinePackageTax>
        </OnlinePackageInfo>

        <SwitchersSection>
          <PayPeriodSwitcher
            isYearly={isYearly}
            onChange={onChange}
            disabled={!isCurrentPlanOnlineService}
          />
          <PriceSwitcher>
            <Switcher>
              <PeriodName isActive>{t('invoicing_feature')}</PeriodName>
              <Switch
                key={'isInvoicing'}
                checked={isInvoicing}
                onChange={(value) => {
                  onChangeInvoicing(value);
                }}
                disabled={!isCurrentPlanOnlineService}
              />
            </Switcher>
            <AdditionalPrice>
              {invoicingSubscriptionPrices
                ? Number(invoicingSubscriptionPrices) / 100
                : 0}{' '}
              € {perPeriod}
            </AdditionalPrice>
          </PriceSwitcher>
        </SwitchersSection>
        <DividerStyled />
        <OnlinePackageInfo>
          <OnlinePackageTitle>
            <TeleconsultationChatsIcon />
            {t('teleconsultationTitle')}
          </OnlinePackageTitle>
          <OnlinePackageDescription>
            {t('percent_of_consultation')}
          </OnlinePackageDescription>
          <OnlinePackageTax>{t('percentage_of_consultation')}</OnlinePackageTax>
        </OnlinePackageInfo>
        <DividerStyled />
        <Features packageType={PackFeatures.privateDoctorPack} />
      </Content>
      <BottomSection>
        {isCurrentPlanOnlineService || !subscriptionPackage ? (
          <SecondaryButton
            type="button"
            onClick={onSubmit}
            disabled={!isPlanChanged}
          >
            {subscriptionPackage
              ? t('change_plan.update_current_plan')
              : t('change_plan.switch_to_plan')}
          </SecondaryButton>
        ) : (
          <MessageStyled>
            <span>*</span>
            <div>
              <p>{t('change_plan.online_service_info.text_1')}</p>
              <p>{t('change_plan.online_service_info.text_2')}</p>
            </div>
          </MessageStyled>
        )}
      </BottomSection>
    </Wrapper>
  );
};

export default OnlinePackage;
