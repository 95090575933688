import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentTypeResponseDto, Specialization } from '@docbay/schemas';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { Dropdown, DropdownClinics } from 'common/components';
import {
  DropdownSection,
  DropdownList,
  Specializations,
  AppointmentTypesWrapper,
  StyledRestartIcon,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchSpecializations } from 'applicaiton/store/reducers/Specializations/ActionCreators';
import { AppointmentTypeOption } from 'common/components/DropdownAppointmentTypes/models';
import DropdownAppointmentTypes from 'common/components/DropdownAppointmentTypes';
import { ClinicDropdownSectionProps } from './modules';
import { resetProfessionalAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import { fetchAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

const ClinicDropdownSection: FC<ClinicDropdownSectionProps> = ({
  getValues,
  clearErrors,
  watch,
  setValue,
  errors,
  arrayIndex,
  disableEditing = false,
  hideClinicDropdown,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { specializations } = useAppSelector((state) => state.specializations);
  const { professionalAppointmentTypes } = useAppSelector(
    (state) => state.appointmentTypes,
  );
  const { clinics } = useAppSelector((state) => state.clinics);
  const [isPending, setIsPending] = useState(false);
  const [isOpenAppointmentTypes, setIsOpenAppointmentTypes] = useState(false);
  const [addedSpecializations, setAddedSpecializations] = useState<string[]>([
    '',
  ]);
  const [addedAppointmentTypes, setAddedAppointmentTypes] = useState<
    AppointmentTypeOption[]
  >([]);

  const canManageAppointmentType = useMemo(() => {
    const currentClinic = clinics.find(
      (clinic) => clinic.id === watch('clinic'),
    );
    const currentClinicOwnerId = currentClinic?.owner?.id;
    const { hasAppointmentTypeConfigurationPermission } =
      featuresPermissions(currentClinicOwnerId);

    return hasAppointmentTypeConfigurationPermission;
  }, [watch('clinic'), clinics]);

  useEffect(() => {
    dispatch(fetchSpecializations());
    setAddedSpecializations(['']);
    clearErrors();
  }, [arrayIndex]);

  useEffect(() => {
    if (getValues('specializationsToAdd').length) {
      setAddedSpecializations(getValues('specializationsToAdd'));
    }
  }, [watch('specializationsToAdd')]);

  const getAppointmentTypesOptions = (data: AppointmentTypeResponseDto[]) => {
    const options = data.map((item: any) => ({
      label: item?.title!,
      value: item?.id!,
      colorCode: item?.colorCode,
      duration: item?.durationInMinutes,
    }));

    return options;
  };

  useEffect(() => {
    if (professionalAppointmentTypes) {
      const options = getAppointmentTypesOptions(professionalAppointmentTypes);

      setAddedAppointmentTypes(options);
    }
  }, [professionalAppointmentTypes]);

  const specializationsOptions = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${language.toUpperCase()}`;

    const options = specializations.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });

    return options;
  }, [specializations, i18n.language]);

  const handleAddSpecialization = () => {
    setAddedSpecializations((prev) => [...prev, '']);
  };

  const handleRemoveSpecialization = (index: number) => {
    const specializationsToAdd = getValues('specializationsToAdd');
    changeAppointmentTypes(specializationsToAdd[index]);

    setValue(
      'specializationsToAdd',
      specializationsToAdd.filter((item: string, i: number) => i !== index),
    );
  };

  const handleEditSpecializations = (index: number, value: string) => {
    changeAppointmentTypes(addedSpecializations[index]);

    const editedSpecializations = addedSpecializations.map((item, i) => {
      if (index === i) {
        return value;
      }
      return item;
    });

    setValue('specializationsToAdd', editedSpecializations);
    clearErrors('specializationsToAdd');
  };

  const changeAppointmentTypes = (specializationId: string) => {
    const appointmentTypesToDelete = professionalAppointmentTypes.filter(
      (item) => item?.specialization?.id === specializationId,
    );
    const appointmentTypesIdsToDelete = appointmentTypesToDelete.map(
      (item) => item.id,
    );

    setValue('appointmentTypesToDelete', appointmentTypesIdsToDelete);

    const appointmentTypes = professionalAppointmentTypes.filter(
      (item) => item?.specialization?.id !== specializationId,
    );
    const options = getAppointmentTypesOptions(appointmentTypes);
    setAddedAppointmentTypes(options);
  };

  const canBeAdded = useMemo(() => {
    return !addedSpecializations.includes('');
  }, [addedSpecializations]);

  const handleClinicChange = (id: string) => {
    setValue('clinic', id);
    clearErrors('clinic');
    setValue('specializationsToAdd', []);
    setValue('appointmentTypes', []);
    setAddedSpecializations(['']);
    dispatch(resetProfessionalAppointmentTypes());
  };

  const handleAppointmentTypesChange = (
    appointments: AppointmentTypeOption[],
  ) => {
    const deletedAppointmentsTypes = professionalAppointmentTypes.filter(
      (item) => {
        const findProfessional = appointments.find((existingItem) => {
          return existingItem.value === item.id;
        });

        if (!findProfessional) {
          return item;
        }

        return;
      },
    );

    setAddedAppointmentTypes(appointments as AppointmentTypeOption[]);

    const appointmentTypeIds = appointments.map((item) => String(item.value));
    const appointmentTypeToDeleteIds = deletedAppointmentsTypes.map((item) =>
      String(item.id),
    );

    setValue('appointmentTypes', appointmentTypeIds);
    setValue('appointmentTypesToDelete', appointmentTypeToDeleteIds);
    appointments.length && clearErrors('appointmentTypes');
  };

  const handleRefreshAppointmentTypes = () => {
    const clinicId = getValues('clinic');
    const specializationIds = watch('specializationsToAdd')
      .map((item: string) => String(item))
      .join();

    if (clinicId && specializationIds) {
      setIsPending(true);
      dispatch(
        fetchAppointmentTypes({
          clinicId: clinicId,
          specializationIds: specializationIds,
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setIsOpenAppointmentTypes(true);
          setIsPending(false);
        }
      });
    }
  };

  return (
    <DropdownSection>
      {!hideClinicDropdown ? (
        <DropdownClinics
          isError={!!errors?.clinic?.message}
          value={watch('clinic')}
          label={t('professional_profile.select_clinic')!}
          placeholder={t('professional_profile.select_clinic')!}
          onChange={(value) => {
            handleClinicChange(String(value));
          }}
          errorMessage={errors?.clinic?.message}
          disabled={disableEditing}
        />
      ) : (
        <></>
      )}
      <DropdownList isError={errors?.specializationsToAdd?.message}>
        <label>{t('professional_profile.specialization')}</label>
        <Specializations>
          {addedSpecializations.map((item, index) => (
            <div key={index}>
              <Dropdown
                id={'specializationsToAdd'}
                value={item}
                placeholder={t(
                  'professional_profile.specialization_placeholder',
                )}
                onChange={(value) => {
                  if (!Array.isArray(value)) {
                    handleEditSpecializations(index, String(value.value));
                  }
                }}
                options={specializationsOptions}
                withSearch={true}
                errorMessage={errors?.specializationsToAdd?.message}
                disabled={disableEditing}
              />
              {index ? (
                <button onClick={() => handleRemoveSpecialization(index)}>
                  <CloseIcon />
                </button>
              ) : (
                ''
              )}
            </div>
          ))}
        </Specializations>
        <button
          disabled={!canBeAdded}
          type="button"
          onClick={() => handleAddSpecialization()}
        >
          <AddIcon />
          <h2>{t('add_another')}</h2>
        </button>
      </DropdownList>
      {canManageAppointmentType && (
        <AppointmentTypesWrapper>
          <DropdownAppointmentTypes
            label={t('appointment_types.type_of_appointments') || ''}
            value={addedAppointmentTypes}
            onChange={(value) => {
              handleAppointmentTypesChange(value as AppointmentTypeOption[]);
            }}
            clinicId={watch('clinic')}
            specializationIds={watch('specializationsToAdd')
              .map((item: string) => String(item))
              .join()}
            withSearch={false}
            isMulti={true}
            errorMessage={errors?.appointmentTypes?.message}
            disabled={disableEditing}
            isOpen={isOpenAppointmentTypes}
          />
          <StyledRestartIcon
            $isPending={isPending}
            onClick={handleRefreshAppointmentTypes}
          />
        </AppointmentTypesWrapper>
      )}
    </DropdownSection>
  );
};

export default ClinicDropdownSection;
