import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  AdditionalPrice,
  DividerStyled,
  OnlinePackageDescription,
  OnlinePackageInfo,
  OnlinePackageTax,
  OnlinePackageTitle,
  PeriodName,
  PriceSwitcher,
  Switcher,
  SwitchersSection,
} from './styles';
import Switch from 'common/components/Switch';
import Features from '../Features';
import { useAppSelector } from 'common/hooks/redux';
import { PackFeatures } from 'features/feature-clinic-registration/constants';
import { ReactComponent as UnreadChatsIcon } from 'applicaiton/assets/chats/unread-chats.svg';
import { ReactComponent as TeleconsultationChatsIcon } from 'applicaiton/assets/chats/teleconsultation-chats.svg';
import PayPeriodSwitcher from '../PayPeriodSwitcher';
import { useTranslation } from 'react-i18next';

interface Props {
  isYearly: boolean;
  isEnabled: boolean;
  isActive: boolean;
  onChange: (value: boolean) => void;
  onChangeInvoicing: (value: boolean) => void;
}

const OnlinePackage: FC<Props> = ({
  isActive,
  isYearly,
  onChange,
  isEnabled,
  onChangeInvoicing,
}) => {
  const { t } = useTranslation();
  const [isInvoicing, setIsInvoicing] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsInvoicing(false);
    }
  }, [isActive]);

  const { subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );

  const invoicingSubscriptionPrices = useMemo(() => {
    const invoicingSubscription = subscriptions?.find(
      (item) => String(item.type) === PackFeatures.invoicingFeature,
    );

    return invoicingSubscription?.prices?.length
      ? invoicingSubscription?.prices[isYearly ? 0 : 1].unitAmountDecimal
      : 0;
  }, [subscriptions, isYearly]);

  const perPeriod = useMemo(() => {
    return isYearly ? t('per_year') : t('per_month');
  }, [isYearly]);

  return (
    <Wrapper>
      <OnlinePackageInfo>
        <OnlinePackageTitle>
          <UnreadChatsIcon />
          {t('clinicsConfiguration.aside_menu.doc_box')}
        </OnlinePackageTitle>
        <OnlinePackageDescription>
          {t('percent_of_subscription')}
        </OnlinePackageDescription>
        <OnlinePackageTax> {t('percentage')}</OnlinePackageTax>
      </OnlinePackageInfo>

      <SwitchersSection>
        <PayPeriodSwitcher
          isActive={isActive}
          isYearly={isYearly}
          isEnabled={isEnabled}
          onChange={onChange}
        />
        <PriceSwitcher>
          <Switcher>
            <PeriodName isActive={isInvoicing}>
              {t('invoicing_feature')}
            </PeriodName>
            <Switch
              key={'isInvoicing'}
              checked={isInvoicing}
              onChange={(value) => {
                setIsInvoicing(value);
                onChangeInvoicing(value);
              }}
            />
          </Switcher>
          <AdditionalPrice>
            {invoicingSubscriptionPrices
              ? Number(invoicingSubscriptionPrices) / 100
              : 0}{' '}
            € {perPeriod}
          </AdditionalPrice>
        </PriceSwitcher>
      </SwitchersSection>

      <DividerStyled />
      <OnlinePackageInfo>
        <OnlinePackageTitle>
          <TeleconsultationChatsIcon />
          {t('teleconsultationTitle')}
        </OnlinePackageTitle>
        <OnlinePackageDescription>
          {t('percent_of_consultation')}
        </OnlinePackageDescription>
        <OnlinePackageTax>{t('percentage_of_consultation')}</OnlinePackageTax>
      </OnlinePackageInfo>
      <DividerStyled />
      <Features packageType={PackFeatures.privateDoctorPack} />
    </Wrapper>
  );
};

export default OnlinePackage;
