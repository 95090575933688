import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckIcon, CloseIcon } from 'applicaiton/assets';
import { AllChatsIcon } from 'applicaiton/assets/chats';

import { useFeatures } from 'features/feature-clinic-registration/hooks/useFeatures';

import { FeaturesProps, ListItem } from './models';
import { FeatureItem, StyledDiv, Title } from './styles';

const Features: FC<FeaturesProps> = ({ packageType }) => {
  const { t } = useTranslation();
  const list = useFeatures(packageType);

  return (
    <StyledDiv>
      <Title>{t('features')}</Title>
      <div>
        {list?.map((item: ListItem) => {
          return (
            <FeatureItem key={item.title} isActive={item.isActive}>
              {item.isActive ? (
                item.isMailIcon ? (
                  <AllChatsIcon />
                ) : (
                  <CheckIcon />
                )
              ) : (
                <CloseIcon />
              )}
              <p>{item.title}</p>
            </FeatureItem>
          );
        })}
      </div>
    </StyledDiv>
  );
};

export default Features;
