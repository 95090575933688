import React, { useEffect, useMemo, useState } from 'react';
import Modal from '@mui/material/Modal';
import { NewMassMessagesModalProps } from './module';
import {
  ModalContentStyled,
  ModalHeaderStyled,
  ModalStyled,
  ModalActionsStyled,
} from './styles';
import { CloseIcon } from 'applicaiton/assets';
import { useTranslation } from 'react-i18next';
import { Dropdown, PrimaryButton, SecondaryButton } from 'common/components';
import { PatientBriefResponseDto } from '@docbay/schemas';
import { useAppDispatch } from 'common/hooks/redux';
import { setMassMessagesPatientsIds } from 'applicaiton/store/reducers/Chats/ChatsSlice';
import { useChats } from '../../hooks/useChats';
import { fetchSubscribedPatients } from 'applicaiton/store/reducers/Professionals/ActionCreators';

export const NewMassMessagesModal = ({
  isOpen,
  onClose,
}: NewMassMessagesModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [patientIds, setPatientId] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<PatientBriefResponseDto[]>(
    [],
  );
  const { setSelectedChat } = useChats();

  useEffect(() => {
    dispatch(fetchSubscribedPatients('')).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as PatientBriefResponseDto[];
        setSearchResults(payload);
      }
    });
  }, []);

  const patientOptions = useMemo(() => {
    if (!searchResults.length) return [];

    return searchResults.map((patient) => {
      return {
        label: `${patient.firstName} ${patient.lastName}`,
        value: patient.id,
      };
    });
  }, [searchResults]);

  const handleCloseModal = () => {
    setPatientId([]);
    onClose();
  };

  const handleSetPatientsId = () => {
    setSelectedChat(null);

    const massMessagePatients = patientIds.map((item) => {
      return searchResults.find((patient) => patient.id === item);
    });

    dispatch(setMassMessagesPatientsIds(massMessagePatients));
    handleCloseModal();
  };

  return (
    <Modal open={isOpen}>
      <ModalStyled>
        <ModalHeaderStyled>
          <p className={'title'}>
            {t('chats.new_chat_modal.new_mass_messages')}
          </p>
          <CloseIcon onClick={handleCloseModal} />
        </ModalHeaderStyled>
        <ModalContentStyled>
          <Dropdown
            id={'search_for_mass_message'}
            placeholder={
              t('chats.new_chat_modal.mass_search_placeholder') || ''
            }
            value={patientIds}
            onChange={(value) => {
              setPatientId(value as string[]);
            }}
            options={patientOptions}
            isMulti={true}
            label={t('chats.new_chat_modal.new_mass_message_with') || ''}
            withSearch={true}
            searchPlaceholder={
              t('chats.new_chat_modal.mass_search_placeholder') || ''
            }
          />
        </ModalContentStyled>
        <ModalActionsStyled>
          <SecondaryButton onClick={handleCloseModal}>
            {t('cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={handleSetPatientsId} disabled={false}>
            {t('ok')}
          </PrimaryButton>
        </ModalActionsStyled>
      </ModalStyled>
    </Modal>
  );
};
