import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ClinicOwnerCreationDto,
  ConfirmSignUpDto,
  SubscriptionPackageDto,
  PaymentDto,
  InvoiceResponseDto,
  SubscriptionPackageResponse,
} from '@docbay/schemas';
import { getMandateInfo } from 'integration/api/stripe';
import {
  ClinicOwnerActions,
  StripeActions,
} from 'applicaiton/constants/actions';
import { ClinicOwnerAPI } from 'integration/api/clinicOwner';
import { getSubscriptions } from 'integration/api/subscriptions';
import { AxiosError } from 'axios';
import { setClinicOwnerRegistrationSessionStorage } from 'applicaiton/sessionStorage/clinicRegistration';
import { ApplyPromocodeRequestProps } from './models';

export const clinicOwnerSignUp = createAsyncThunk(
  ClinicOwnerActions.signUpOwner,
  async (data: ClinicOwnerCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ClinicOwnerAPI.clinicOwnerSignUp(
        data,
      );
      setClinicOwnerRegistrationSessionStorage('ownerId', {
        ownerId: responseData.id,
      });
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const clinicOwnerConfirmSignUp = createAsyncThunk(
  ClinicOwnerActions.signUpConfirmOwner,
  async (data: ConfirmSignUpDto, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ClinicOwnerAPI.clinicOwnerConfirmSignUp(data);
      setClinicOwnerRegistrationSessionStorage('stripeDebit', responseData);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getMandateInfoAction = createAsyncThunk(
  StripeActions.getMandateInfo,
  async (intentId: string, { rejectWithValue }) => {
    try {
      const { data } = await getMandateInfo(intentId);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getSubscriptionsAction = createAsyncThunk(
  StripeActions.fetchSubscriptions,
  async (_, { rejectWithValue }) => {
    try {
      const { data }: { data: SubscriptionPackageResponse[] } =
        await getSubscriptions();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const clinicOwnerPayments = createAsyncThunk(
  StripeActions.clinicOwnerPayments,
  async (params: { data: PaymentDto; token: string }, { rejectWithValue }) => {
    try {
      const { data }: { data: InvoiceResponseDto } =
        await ClinicOwnerAPI.clinicOwnerPayments(params.data, params.token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const resendConfirmationCode = createAsyncThunk(
  ClinicOwnerActions.resendConfirmationCode,
  async (email: string, { rejectWithValue }) => {
    try {
      const { data }: { data: SubscriptionPackageDto[] } =
        await ClinicOwnerAPI.resendConfirmationCode(email);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const applyClinicOwnerPromocode = createAsyncThunk(
  ClinicOwnerActions.applyClinicOwnerPromocode,
  async (data: ApplyPromocodeRequestProps, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ClinicOwnerAPI.applyClinicOwnerPromocode(
          { promocode: data.promocode, packages: data.packages },
          data.token,
        );
      setClinicOwnerRegistrationSessionStorage('promoCode', responseData);

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.response);
    }
  },
);

export const getClientSecret = createAsyncThunk(
  ClinicOwnerActions.getClientSecret,
  async (token: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ClinicOwnerAPI.getClientSecret(
        token,
      );

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
