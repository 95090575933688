import React, { FC, useMemo, useState } from 'react';
import {
  Wrapper,
  Subscription,
  WorkingHours,
  WorkingSchedule,
  WorkingDays,
} from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { useTranslation } from 'react-i18next';
import { EditSubscription } from 'applicaiton/store/reducers/Professionals/models';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import AddSubscriptionModal from '../AddSubscriptionModal';
import {
  ClinicProfessionalUpdateDto,
  CpSubscriptionUpdateDto,
} from '@docbay/schemas';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import dayjs from 'dayjs';
import { ClinicProfessionalSubscriptionType } from 'common/types/subscriptions';

interface Props {
  data: EditSubscription;
  onDelete: (data: EditSubscription) => void;
}
const SubscriptionItem: FC<Props> = ({ data, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const { profileChanges, subscriptions, editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );

  const isCompanySubscription =
    data?.type === ClinicProfessionalSubscriptionType.COMPANY;

  const currentClinic = useMemo(() => {
    return editedProfessional?.clinicsRelations?.find(
      (item) => item?.clinic?.id === data.clinicId,
    );
  }, [editedProfessional, subscriptions]);

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const handleOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleEditSubscription = (data: EditSubscription) => {
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    if (profileChanges?.clinicsRelations?.length) {
      profileChanges?.clinicsRelations?.map((clinic) => {
        if (data.id?.includes('-')) {
          const indexOfObject = subscriptions.findIndex(
            (object) => object.id === data.id,
          );

          const newSubscriptions = [...subscriptions!];

          newSubscriptions!.splice(indexOfObject, 1, data);
          const subs = newSubscriptions.filter(
            (item) => item.clinicId === data.clinicId && item.id!.includes('-'),
          );

          const newSubs = subs.map((item) => {
            return {
              title: item.title!,
              price: Number(item.price)!,
              durationInWeeks: item.durationInWeeks!,
              teleconsultationMinutes: item.teleconsultationMinutes!,
              onlineHours: item.onlineHours,
              type: item.type,
              minNumberOfEmployees: item.minNumberOfEmployees,
              maxNumberOfEmployees: item.maxNumberOfEmployees,
            };
          });

          newClinicsRelations.push({
            clinicId: data.clinicId!,
            subscriptionsToAdd: newSubs!,
            ...(clinic.subscriptionsToUpdate
              ? { subscriptionsToUpdate: clinic.subscriptionsToUpdate }
              : []),
            ...(clinic.subscriptionsToDelete
              ? { subscriptionsToDelete: clinic.subscriptionsToDelete }
              : []),
          });
        } else {
          newClinicsRelations.push({
            clinicId: data.clinicId!,
            ...(clinic.subscriptionsToAdd
              ? { subscriptionsToAdd: clinic.subscriptionsToAdd }
              : []),
            ...(clinic.subscriptionsToDelete
              ? { subscriptionsToDelete: clinic.subscriptionsToDelete }
              : []),
            subscriptionsToUpdate: [
              ...((clinic?.subscriptionsToUpdate?.filter(
                (item) => item.id !== data.id,
              ) as CpSubscriptionUpdateDto[]) || []),
              data,
            ],
          });
        }
      });
    } else {
      newClinicsRelations.push({
        clinicId: data.clinicId!,
        subscriptionsToUpdate: [data],
      });
    }

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    const indexOfObject = subscriptions!.findIndex(
      (object) => object.id === data.id,
    );

    const newSubscriptions = [...subscriptions];

    newSubscriptions!.splice(indexOfObject, 1, data);

    dispatch(
      professionalProfileActions.setProfileSubscriptions(newSubscriptions),
    );
    dispatch(professionalProfileActions.setProfileChanges(prof!));
    handleCloseEditModal();
  };

  const handleDelete = () => {
    onDelete(data);
  };

  const durationText = useMemo(() => {
    const duration = Number(data?.durationInWeeks) || 0;
    const durationNumber = duration > 3 ? duration / 4 : duration;

    const durationName =
      duration > 3
        ? t(
            `professional_profile.subscriptions.${
              duration / 4 === 1 ? 'month' : 'months'
            }`,
          )
        : t(
            `professional_profile.subscriptions.${
              duration === 1 ? 'week' : 'weeks'
            }`,
          );

    return `${durationNumber} ${durationName}`;
  }, [data, t]);

  return (
    <Subscription>
      <Wrapper onClick={handleOpenEditModal}>
        <p>{data.title}</p>
        <p>
          {t('clinic')}:<span>{currentClinic?.clinic?.name || ''}</span>
        </p>
        {isCompanySubscription && (
          <p>
            {t('professional_profile.subscriptions.employee_range')}:
            <span>
              {`${data.minNumberOfEmployees} - ${data.maxNumberOfEmployees} ${t(
                'professional_profile.subscriptions.employees',
              )}`}
            </span>
          </p>
        )}
        <p>
          {t(
            isCompanySubscription
              ? 'professional_profile.subscriptions.price_label'
              : 'price',
          )}
          :<span>€{data.price}</span>
        </p>
        <p>
          {t('professional_profile.subscriptions.duration')}:
          <span>{durationText}</span>
        </p>
        <p>
          {t('professional_profile.subscriptions.teleconsultation')}:
          <span>
            {data.teleconsultationMinutes} {t('mins')}
          </span>
        </p>
        {!!data?.onlineHours?.length && (
          <WorkingSchedule>
            <h2>{t('professional_profile.subscriptions.online_hours_for')}</h2>
            <WorkingDays>
              {data.onlineHours!.map((item) => (
                <div key={item.day}>
                  <p>{t(item.day.toLowerCase())}</p>
                  <WorkingHours>
                    {item.slots.map((hour, index) => (
                      <p key={index}>
                        {dayjs(hour.startTime, 'HH:mm').format('HH:mm')} -{' '}
                        {dayjs(hour.endTime, 'HH:mm').format('HH:mm')}
                      </p>
                    ))}
                  </WorkingHours>
                </div>
              ))}
            </WorkingDays>
          </WorkingSchedule>
        )}
      </Wrapper>
      <CloseIcon onClick={handleDelete} />
      {isOpenEditModal && (
        <AddSubscriptionModal
          key={'edit'}
          isOpen={isOpenEditModal}
          onClose={handleCloseEditModal}
          onAdd={handleEditSubscription}
          data={data}
          type={data.type}
        />
      )}
    </Subscription>
  );
};

export default SubscriptionItem;
