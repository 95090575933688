import { Box, styled } from '@mui/system';

export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  div:first-of-type {
    flex: 1;
  }
  div:last-of-type {
    flex: 0;
  }
  .optionListMulti {
    min-width: fit-content;
  }
  > div:last-of-type {
    section {
      h2 {
        margin-right: 0px;
      }
    }
    .optionListMulti {
      width: 340px;
    }
  }
`;
