import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { getIsUserPrivateDoctor } from 'applicaiton/sessionStorage/auth';

import Switch from 'common/components/Switch';
import { useAppSelector } from 'common/hooks/redux';
import { PrimaryButton, SecondaryButton } from 'common/components';

import { PackFeatures } from 'features/feature-clinic-registration/constants';
import { getCurrentPeriodPrice } from 'features/feature-manage-plan/helpers/getCurrentPeriodPrice';

import OnlinePackage from '../OnlinePackage';
import PayPeriodSwitcher from '../PayPeriodSwitcher';
import Counter from '../Counter';
import Features from '../Features';

import { PackageProps } from './models';
import {
  PackageInfoStyled,
  Wrapper,
  MainPrice,
  AdditionalPrice,
  PriceSwitcher,
  Switcher,
  PeriodName,
  SwitchersSection,
  ButtonWrapper,
  ErrorMessage,
  CounterWrapper,
  MessageStyled,
} from './styles';

const Package: FC<PackageProps> = ({
  quantity,
  handleSetQuantity,
  id,
  title,
  isActive,
  onClick,
  isPrivateDoctor,
  packageType,
  prices,
  isYearly,
  isInvoicing,
  handlePeriodChange,
  handleChangeInvoicing,
  onSubmit,
  showQuantityError,
}) => {
  const { t, i18n } = useTranslation();
  const isUserPrivateDoctor = getIsUserPrivateDoctor();

  const { subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const { subscriptionPackage, featurePackages } = useAppSelector(
    (state) => state.clinicOwnerSubscription,
  );

  const isCurrentPlan = subscriptionPackage?.subscriptionType === packageType;
  const isCurrentPlanOnlineService =
    String(subscriptionPackage?.subscriptionType) ===
    PackFeatures.privateDoctorPack;
  const canSelectPrivateDoctorPlan =
    isUserPrivateDoctor && packageType === PackFeatures.privateDoctorPack;
  const canSelectOtherPlans =
    !isUserPrivateDoctor && packageType !== PackFeatures.privateDoctorPack;

  const canChangePlan = canSelectPrivateDoctorPlan || canSelectOtherPlans;

  const price = getCurrentPeriodPrice(prices || [], isYearly);
  const pricePerDoctor = price?.tiers?.length
    ? Number(price.tiers[0].unitAmountDecimal)
    : 0;
  const pricePerAdditionalDoctor = price?.tiers?.length
    ? Number(price.tiers[1].unitAmountDecimal)
    : 0;

  const showErrorMessage = showQuantityError && isActive;

  const invoicingSubscriptionPrices = useMemo(() => {
    const invoicingSubscription = subscriptions?.find(
      (item) => String(item.type) === PackFeatures.invoicingFeature,
    );

    const price = getCurrentPeriodPrice(
      invoicingSubscription?.prices || [],
      isYearly,
    );

    return price?.unitAmountDecimal || 0;
  }, [subscriptions, isYearly]);

  const description = useMemo(() => {
    return isPrivateDoctor
      ? t('packs_description.private_doctor')
      : t('packs_description.other_packs');
  }, [i18n.language]);

  const handleSelectPlan = () => {
    if (canChangePlan) {
      onClick();
    }
  };

  const isPlanChanged = useMemo(() => {
    if (isCurrentPlan) {
      const isYearlySubscription =
        dayjs(subscriptionPackage.purchasedSubscription?.currentPeriodEnd).diff(
          subscriptionPackage.purchasedSubscription?.currentPeriodStart,
          'month',
        ) > 1;

      const isPeriodOfSubscriptionChanged = isYearlySubscription !== isYearly;
      const isFeaturePackagesChanged = !!featurePackages.length !== isInvoicing;
      const isNumberOfProfessionalChanged =
        subscriptionPackage.purchasedSubscription?.numberOfProfessionals !==
        quantity;

      return (
        isPeriodOfSubscriptionChanged ||
        isFeaturePackagesChanged ||
        isNumberOfProfessionalChanged
      );
    }
    return true;
  }, [
    isCurrentPlan,
    subscriptionPackage,
    featurePackages,
    isYearly,
    isInvoicing,
    quantity,
  ]);

  const disableSubmitButton = !isActive || !isPlanChanged || quantity === 0;

  return (
    <Wrapper onMouseDown={handleSelectPlan} isActive={isActive}>
      <div>
        <PackageInfoStyled>
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </PackageInfoStyled>
      </div>
      {isPrivateDoctor ? (
        <OnlinePackage
          isYearly={isYearly}
          isInvoicing={isInvoicing}
          onChange={handlePeriodChange}
          onChangeInvoicing={handleChangeInvoicing}
          onSubmit={onSubmit}
          isPlanChanged={isPlanChanged}
        />
      ) : (
        <div>
          <CounterWrapper>
            <Counter
              value={quantity}
              handleSetQuantity={handleSetQuantity}
              id={id}
              disabled={!canChangePlan}
            />
            {showErrorMessage && (
              <ErrorMessage>
                {t('errors.upgrade_plan_min_quantity')}
              </ErrorMessage>
            )}
          </CounterWrapper>
          <div>
            <MainPrice>
              <span>{pricePerDoctor / 100} €</span>{' '}
              {isYearly ? t('per_doctor_year') : t('per_doctor_month')}
            </MainPrice>
            <AdditionalPrice>
              + {pricePerAdditionalDoctor / 100} €{t('each_additional_doctor')}
            </AdditionalPrice>
          </div>
          <SwitchersSection>
            <PayPeriodSwitcher
              isYearly={isYearly}
              onChange={handlePeriodChange}
              disabled={!canChangePlan}
            />
            {String(packageType) === PackFeatures.clinicFullPack && (
              <PriceSwitcher>
                <Switcher>
                  <PeriodName isActive={isInvoicing}>
                    {t('invoicing_feature')}
                  </PeriodName>
                  <Switch
                    checked={isInvoicing}
                    onChange={(value) => handleChangeInvoicing(value)}
                    disabled={!canChangePlan}
                  />
                </Switcher>
                <AdditionalPrice>
                  {invoicingSubscriptionPrices
                    ? Number(invoicingSubscriptionPrices) / 100
                    : 0}{' '}
                  € {t('per_month')}
                </AdditionalPrice>
              </PriceSwitcher>
            )}
          </SwitchersSection>
          <Features packageType={packageType} />
          <ButtonWrapper>
            {isCurrentPlanOnlineService ? (
              <MessageStyled>
                <span>*</span>
                <div>
                  <p>{t('change_plan.online_service_info.text_1')}</p>
                  <p>{t('change_plan.online_service_info.text_2')}</p>
                </div>
              </MessageStyled>
            ) : (
              <>
                {isCurrentPlan ? (
                  <SecondaryButton
                    type="button"
                    onClick={onSubmit}
                    disabled={disableSubmitButton}
                  >
                    {t('change_plan.update_current_plan')}
                  </SecondaryButton>
                ) : (
                  <PrimaryButton
                    type="button"
                    onClick={onSubmit}
                    disabled={disableSubmitButton}
                  >
                    {t('change_plan.switch_to_plan')}
                  </PrimaryButton>
                )}
              </>
            )}
          </ButtonWrapper>
        </div>
      )}
    </Wrapper>
  );
};

export default Package;
