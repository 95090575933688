import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PathNames } from 'applicaiton/routes';

import { featuresPermissions } from 'common/helpers/featuresPermissions';
import { Header } from 'common/components';
import PageSwitcher from 'common/components/PageSwitcher';

import { Table } from './components';
import { Wrapper } from './styles';

const RequestList: FC = () => {
  const { t } = useTranslation();
  const { hasWaitingListPermission, hasPatientClinicalFilePermission } =
    featuresPermissions();

  const showSwitcher =
    hasPatientClinicalFilePermission || hasWaitingListPermission;

  return (
    <Wrapper>
      <Header />
      {showSwitcher && (
        <PageSwitcher>
          {hasPatientClinicalFilePermission && (
            <Link to={PathNames.myPatients}>
              {t('clinicsConfiguration.aside_menu.my_patients_path')}
            </Link>
          )}
          {hasWaitingListPermission && (
            <Link to={PathNames.waitingList}>
              {t('clinicsConfiguration.aside_menu.waiting_list')}
            </Link>
          )}
          <div>{t('clinicsConfiguration.aside_menu.request_list')}</div>
        </PageSwitcher>
      )}
      <h1>{t('clinicsConfiguration.aside_menu.request_list')}</h1>
      <Table />
    </Wrapper>
  );
};

export default RequestList;
