import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

export const ModalStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
  background: rgba(234, 236, 240, 0.9);
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  padding: 0px 20px;
  background: ${commonColors.grey200};
  box-shadow: 1px 1px 2px 0px rgba(54, 54, 54, 0.1);

  > button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;

      path {
        fill: ${commonColors.grey600};
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    height: auto;
    max-height: 760px;
    max-width: 1140px;
    border-radius: 8px;
  }
`;
