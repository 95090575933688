import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const AdditionalPrice = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.grey700};
  font-size: 12px;
  line-height: 14.4px;
`;

export const PriceSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: ${({ theme }) => theme.color.grey100};
  border-radius: 8px;
  padding: 8px 12px;
`;

export const Switcher = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 19.6px;
`;

export const PeriodName = styled.div<Props>`
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
`;
