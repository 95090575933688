import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const AdditionalPrice = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.grey700};
  font-size: 12px;
  line-height: 14.4px;
`;

export const PriceSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: ${({ theme }) => theme.color.grey100};
  border-radius: 8px;
  padding: 8px 12px;
`;

export const Switcher = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 19.6px;
`;

export const PeriodName = styled.div<Props>`
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
`;

export const OnlinePackageInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const OnlinePackageTitle = styled.div`
  display: flex;
  gap: 8px;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey800};
`;

export const OnlinePackageDescription = styled.div`
  font-size: 14px;
  line-height: 19.6px;
  color: ${({ theme }) => theme.color.grey700};
`;

export const OnlinePackageTax = styled.div`
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.grey800};
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
`;

export const SwitchersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
