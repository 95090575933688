import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  padding: 20px 32px;
  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.blue700};
    }
  }

  h2 {
    margin: 4px 0 8px;
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  p {
    color: ${commonColors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    b {
      font-weight: 700;
    }
  }
`;

export const Badges = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 100px;
  background: ${commonColors.grey100};

  p {
    color: ${commonColors.blue800};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;

    path {
      fill: ${commonColors.blue800};
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.grey900};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  height: 38px;
  min-width: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.blue300};
  box-shadow: 1px 1px 2px 0 rgba(54, 54, 54, 0.1);

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;

  svg {
    min-width: 24px;
    height: 24px;
    margin-left: 20px;
  }

  :hover {
    background-color: ${({ theme }) => theme.color.blue400};
  }
`;
