import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider } from '@mui/material';
import {
  Input,
  Loader,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import {
  setFocusOnEmailSessionStorage,
  setClinicOwnerRegistrationSessionStorage,
  currentClinicOwnerRegistration,
  getIsBackModalShowSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';
import ButtonGroup from '../ButtonGroup';
import { StyledForm, ButtonSectionStyled } from './styles';
import { useSetPassword } from 'features/feature-clinic-registration/hooks/useSetPassword';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  clinicOwnerConfirmSignUp,
  resendConfirmationCode,
} from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import {
  ClinicOwnerPackageDto,
  SubscriptionPackageResponse,
} from '@docbay/schemas';
import CreatePassword from 'common/components/CreatePassword';
import BackModal from '../BackModal';
import {
  clearClinicOwnerSuccessSignUp,
  setClinicRegistrationError,
  setIsSuccessClinicOwnerSignUp,
} from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';
import dayjs from 'dayjs';
import Timer from '../Timer';
import { useSetPasswordSchema } from 'features/feature-clinic-registration/hooks/useSetPasswordSchema';
import SuccessRegistrationModal from '../SuccessRegistrationModal';
import { getCurrentPeriodPrice } from 'features/feature-manage-plan/helpers/getCurrentPeriodPrice';

interface SetPasswordProps {
  setStep: (value: number) => void;
}

interface FormData {
  code: number;
  password: string;
}

const SetPassword: FC<SetPasswordProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const passwordInfo = useSetPassword();
  const [openModal, setOpenModal] = useState(false);
  const { isLoading, isClinicOwnerConfirmSignUp, error, isSubscriptionsFree } =
    useAppSelector((state) => state.clinicRegistrationSlice);
  const { personalInfo, subscriptionPackages, privateDoctor } =
    currentClinicOwnerRegistration();
  const [timeChanged, setTimeChanged] = useState(0);
  const [isCodeExpired, setIsCodeExpired] = useState<boolean>(false);
  const setPasswordSchema = useSetPasswordSchema();
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(setPasswordSchema),
    defaultValues: passwordInfo,
    mode: 'onChange',
  });

  useEffect(() => {
    const { timerEndDate } = currentClinicOwnerRegistration();
    const codeExpired = dayjs().isAfter(dayjs(timerEndDate || ''));

    setIsCodeExpired(!!codeExpired);
  }, []);

  useEffect(() => {
    if (isClinicOwnerConfirmSignUp) {
      if (isSubscriptionsFree) {
        setShowModal(true);
      } else {
        setStep(4);
      }
    }
  }, [isClinicOwnerConfirmSignUp, isSubscriptionsFree]);

  useEffect(() => {
    error && setError('code', { message: 'Incorrect code.' });
  }, [error]);

  useEffect(() => {
    if (error) {
      clearErrors('code');
      dispatch(setClinicRegistrationError('Incorrect code.'));
    }
  }, [watch('code')]);

  const changeEmail = () => {
    dispatch(clearClinicOwnerSuccessSignUp());
    setStep(2);
    setFocusOnEmailSessionStorage(true);
  };

  const handleResendCode = async () => {
    const response = await dispatch(
      resendConfirmationCode(personalInfo?.email!),
    );

    if (response.meta.requestStatus === 'fulfilled') {
      const timerCountDownInMinutes = 5;
      setClinicOwnerRegistrationSessionStorage('timerEndDate', {
        timerEndDate: dayjs().set(
          'minute',
          dayjs().get('minute') + timerCountDownInMinutes,
        ),
      });
      setIsCodeExpired(false);
      setTimeChanged(dayjs().get('minute') + timerCountDownInMinutes);
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const packages = Object.values(
      subscriptionPackages!,
    ).flat() as SubscriptionPackageResponse[];

    const registrationData = JSON.parse(
      sessionStorage.getItem('clinicOwnerRegistration') || '{}',
    );
    const isYearly = registrationData.isYearlyPeriod;

    const currentInvoicingPrice = registrationData.isInvoicing
      ? getCurrentPeriodPrice(
          registrationData.subscriptionInvoicingPackages.prices,
          isYearly,
        )
      : null;

    const invoicingPackage = registrationData.isInvoicing
      ? {
          product: registrationData.subscriptionInvoicingPackages.productId,
          price: currentInvoicingPrice?.id!,
          quantity: 1,
          type: registrationData.subscriptionInvoicingPackages.type,
        }
      : {};

    const currentPrice = getCurrentPeriodPrice(packages[0].prices, isYearly);

    const newPackages = [
      ...(packages?.length
        ? [
            {
              product: packages[0].productId,
              price: currentPrice?.id!,
              quantity: packages.length,
              type: packages[0].type,
            },
          ]
        : []),
      ...(registrationData.isInvoicing ? [invoicingPackage] : []),
    ];

    const response = await dispatch(
      clinicOwnerConfirmSignUp({
        ...data,
        code: String(data.code),
        email: personalInfo.email!,
        ...(privateDoctor && { privateDoctor: true }),
        packages: newPackages! as ClinicOwnerPackageDto[],
        privateDoctor: registrationData?.privateDoctor,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      setClinicOwnerRegistrationSessionStorage('setPassword', {
        password: data.password,
      });
    }
  };

  const disabledButton = !!Object.keys(errors).length;

  const handleBackButton = () => {
    if (getIsBackModalShowSessionStorage() === 'N') {
      setOpenModal(true);
    } else {
      dispatch(setClinicRegistrationError(''));
      dispatch(setIsSuccessClinicOwnerSignUp(false));
      setStep(2);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Timer
            timeChanged={timeChanged}
            isCodeExpired={isCodeExpired}
            setIsCodeExpired={setIsCodeExpired}
          />
          <Input
            id="code"
            label={t('clinicRegistration.set_password.verification_code') || ''}
            type="number"
            placeholder={
              t(
                'clinicRegistration.set_password.verification_code_placeholder',
              ) || ''
            }
            errorMessage={
              (errors.code?.message && t('errors.incorrect_code')) || ''
            }
            register={register}
            disabled={isCodeExpired}
          />
          <ButtonSectionStyled>
            {isCodeExpired ? (
              <h2>{t('clinicRegistration.set_password.code_expired')}</h2>
            ) : (
              <p>{t('clinicRegistration.set_password.still_no_code')}</p>
            )}
            <div>
              <button type="button" onClick={() => changeEmail()}>
                {t('clinicRegistration.set_password.use_other_email')}
              </button>
              <Divider orientation="vertical" />
              <button type="button" onClick={() => handleResendCode()}>
                {t('clinicRegistration.set_password.resend_code')}
              </button>
            </div>
          </ButtonSectionStyled>
          <Divider />
          <CreatePassword
            watch={watch}
            getValues={getValues}
            register={register}
            errors={errors.password?.message}
          />
        </div>
        <ButtonGroup>
          <PrimaryButton disabled={disabledButton} type="submit">
            {t('clinicRegistration.continue')}
          </PrimaryButton>
          <SecondaryButton
            onClick={handleBackButton}
            disabled={false}
            type="button"
          >
            {t('clinicRegistration.back')}
          </SecondaryButton>
        </ButtonGroup>
      </StyledForm>
      <BackModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        backToStep={() => setStep(2)}
      />
      <SuccessRegistrationModal opened={showModal} />
    </>
  );
};

export default SetPassword;
