import { Modal } from '@mui/material';
import React, { FC } from 'react';

import { CloseIcon } from 'applicaiton/assets';

import { PreviewModalProps } from './modal';
import { ModalStyled, HeaderStyled, Content } from './styles';

const PreviewModal: FC<PreviewModalProps> = ({ open, onClose, image }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalStyled>
        <HeaderStyled>
          <button type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </HeaderStyled>
        <Content>
          <img src={image} alt="image-preview" />
        </Content>
      </ModalStyled>
    </Modal>
  );
};

export default PreviewModal;
