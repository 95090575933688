import React, { FC, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { SetupIntent } from 'common/types/stripe';

import { Header, PlansList, BillingInfo, PaymentProcess } from './components';
import { Wrapper } from './styles';

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY || '';
const stripePromise = loadStripe(PUBLIC_KEY);

const ManagePlan: FC = () => {
  const [step, setStep] = useState(1);
  const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);

  const firstStep = step === 1;
  const secondStep = step === 2;
  const thirdStep = step === 3;

  return (
    <Wrapper>
      <Header step={step} />
      {firstStep && <PlansList setStep={setStep} />}
      <Elements stripe={stripePromise}>
        {secondStep && (
          <BillingInfo setStep={setStep} setSetupIntent={setSetupIntent} />
        )}
        {thirdStep && (
          <PaymentProcess setupIntent={setupIntent} setStep={setStep} />
        )}
      </Elements>
    </Wrapper>
  );
};

export default ManagePlan;
