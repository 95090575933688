import {
  ClinicOwnerCurrentSubscriptionResponseDto,
  ClinicOwnerUpdatePurchasedSubscriptionDto,
  CustomerPaymentMethodDto,
} from '@docbay/schemas';

import axiosInstance from 'integration/api/axiosInstance';

export const ClinicOwnerSubscriptionAPI = {
  getCurrentSubscription: () => {
    return axiosInstance.get<ClinicOwnerCurrentSubscriptionResponseDto>(
      '/clinic-owner-subscriptions/current',
    );
  },
  updateCurrentSubscription: (
    data: ClinicOwnerUpdatePurchasedSubscriptionDto,
  ) => {
    return axiosInstance.patch<ClinicOwnerCurrentSubscriptionResponseDto>(
      '/clinic-owner-subscriptions',
      data,
    );
  },
  getPaymentMethods: () => {
    return axiosInstance.get<CustomerPaymentMethodDto[]>(
      '/clinic-owner/payment-methods',
    );
  },
  setDefaultPaymentMethod: (paymentMethodId: string) => {
    return axiosInstance.post(
      `/clinic-owner/payment-methods/${paymentMethodId}/default`,
    );
  },
};
