import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CloseIcon, AddIcon, WarningIcon } from 'applicaiton/assets';
import { PathNames } from 'applicaiton/routes';
import { setShowLimitSubscriptionsModal } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import { ModalStyled, ButtonSection, InfoSection } from './styles';

const SubscriptionsLimitModal: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, showLimitSubscriptionsModal } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );

  const handleClose = () => {
    dispatch(setShowLimitSubscriptionsModal(false));
  };

  const handleSubmit = () => {
    handleClose();
    navigate(PathNames.addProfessional);
  };

  return (
    <Modal open={showLimitSubscriptionsModal}>
      <>
        {isLoading && <Loader />}
        <ModalStyled>
          <CloseIcon onClick={handleClose} />
          <InfoSection>
            <WarningIcon />
            <h1>{t('subscriptions_limit_modal.title_part1')}</h1>
            <p>{t('subscriptions_limit_modal.text_part1')}</p>
          </InfoSection>
          <ButtonSection>
            <SecondaryButton onClick={handleClose}>
              {t('subscriptions_limit_modal.cancel_text')}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit} type="button">
              <AddIcon />
              {t('professionalsListConfiguration.add_new_professional')}
            </PrimaryButton>
          </ButtonSection>
        </ModalStyled>
      </>
    </Modal>
  );
};

export default SubscriptionsLimitModal;
