import styled from 'styled-components';

import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;

  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h2 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 4px;

    color: ${commonColors.grey800};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      path {
        fill: ${commonColors.green600};
      }
    }

    b {
      font-weight: 700;
    }
  }
`;

export const ListStyled = styled.div`
  display: flex;
  gap: 24px;
`;

export const ConfirmModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.2px;

    span {
      color: ${commonColors.blue700};
    }
  }

  p {
    color: ${commonColors.grey700};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`;
