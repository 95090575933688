import styled from 'styled-components';

export const ButtonGroupStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  button {
    width: 200px;
  }
`;
