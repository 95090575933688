import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';
import { Menu, MenuItem } from '@mui/material';

interface Props {
  disabled: boolean;
  emergency?: boolean;
}

interface MessageWrapperProps {
  isLastMessage: boolean;
}

export const MessagesListWrapper = styled.div`
  flex: 1;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;

  .topic-name {
    flex: 0;
    margin-bottom: 12px;
    color: ${commonColors.grey800};
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.09px;
  }

  .messages-list {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    max-height: calc(100vh - 380px);
    overflow-y: scroll;
  }
`;

export const MessageStyled = styled.div<Props>`
  width: fit-content;
  padding: 14px 14px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  float: right;
  border-radius: 15px 15px 15px 0;
  background: ${({ emergency }) =>
    emergency ? commonColors.error2 + '!important' : commonColors.grey100};

  margin-left: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &.own-message {
    margin-left: auto;
    border-radius: 15px 15px 0 15px;
    background: ${commonColors.blue800};
  }
  .message-text {
    color: ${({ emergency }) =>
      emergency ? commonColors.white + '!important' : commonColors.grey800};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-right: 40px;

    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    field-sizing: content;
  }

  .message-document {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-right: 40px;
    word-break: break-word;

    svg {
      min-height: 20px;
      min-width: 20px;
      margin-right: 8px;
    }
  }

  &.own-message .message-text,
  &.own-message .message-document {
    color: ${commonColors.white};

    svg {
      min-height: 20px;
      min-width: 20px;
    }
  }

  .message-time {
    color: ${({ emergency }) =>
      emergency ? commonColors.white + '!important' : commonColors.grey400};
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
  }

  &.own-message .message-time {
    color: ${commonColors.grey100};
  }
`;

export const TopicClosedMessage = styled('div')`
  width: fit-content;
  display: flex;
  align-items: center;
  background: ${commonColors.blue100};
  padding: 14px;
  gap: 8px;
  margin-left: auto;
  border-radius: 15px 15px 0 15px;
  color: ${commonColors.blue800};
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const MenuStyled = styled(Menu)`
  .MuiPaper-root {
    border-radius: 8px;
    border: 1px solid ${commonColors.grey300};
    background: ${commonColors.white};
    box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .MuiList-padding {
      padding: 0;
    }
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px;
  color: ${commonColors.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${commonColors.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    text-decoration: none;
  }

  svg {
    path {
      fill: ${commonColors.grey700};
    }
  }
`;

export const TeleconsultationMessageStyled = styled('div')`
  width: fit-content;
  min-width: 319px;
  padding: 14px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-radius: 15px 15px 15px 0;
  background: ${commonColors.success4};
  margin-left: 0;
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &.own-message {
    margin-right: 0;
    margin-left: auto;
    border-radius: 15px 15px 0 15px;
    background: ${commonColors.success4};
  }

  .message-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-right: 40px;
  }

  &.own-message .message-text {
    color: ${commonColors.grey800};
  }

  .message-time {
    color: ${commonColors.grey400};
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
  }

  &.own-message .message-time {
    color: ${commonColors.grey400};
  }
`;

export const TeleconsultationIconStyled = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${commonColors.green600};
  margin-right: 4px;

  svg {
    width: 13.333px;
    height: 13.333px;

    path {
      fill: ${commonColors.white};
    }
  }
`;

export const TeleconsutationButtonWrapper = styled('div')`
  width: 100%;
  > button {
    width: fit-content;
    height: 40px;
    background-color: ${commonColors.white};
    svg {
      path {
        fill: ${commonColors.blue700};
      }
    }
  }
`;

export const MessageTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  margin: 16px 0;
  p {
    min-width: fit-content;
    color: ${commonColors.grey500};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
    text-transform: lowercase;
    margin: 0 40px;
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${commonColors.grey200};
`;

export const MessageWrapper = styled.div<MessageWrapperProps>`
  display: flex;
  flex-direction: ${({ isLastMessage }) =>
    isLastMessage ? 'column-reverse' : 'column'};
`;

export const ImageStyled = styled.div`
  position: relative;
  height: auto;
  width: 236px;

  img {
    height: auto;
    width: 236px;
    border-radius: 8px;
  }
`;

export const MoreButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.5);

  &:hover {
  }

  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    path {
      fill: ${commonColors.grey600};
    }
  }
`;

export const ImageMenuStyled = styled.div`
  position: absolute;
  top: 28px;
  right: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 6px;

    color: ${commonColors.grey800};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;

    &:hover {
      background-color: ${commonColors.grey100};
    }

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${commonColors.grey600};
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 236px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    circle {
      stroke: ${commonColors.blue900};
    }
  }
`;
