import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProfessionalResponseDto } from '@docbay/schemas';
import {
  checkProfessionalVerificationCode,
  createProfessionalPassword,
  deleteProfessionals,
  fetchProfessionals,
  fetchProfessionalsForAutosuggest,
  fetchSubscribedPatients,
} from './ActionCreators';
import { ProfessionalsState } from './models';

const initialState: ProfessionalsState = {
  professionals: [],
  professionalsAutosuggest: [],
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
  currentProfessional: null,
  deletedSuccess: false,
  isVerificationCodeExpired: false,
  isCreatePassword: false,
};

const professionalsSlice = createSlice({
  name: 'professionals',
  initialState,
  reducers: {
    selectProfessional: (
      state,
      actions: PayloadAction<ProfessionalResponseDto | null>,
    ) => {
      state.currentProfessional = actions.payload;
      state.deletedSuccess = false;
    },
    setIsCreatePassword: (state, actions) => {
      state.isCreatePassword = actions.payload;
    },
    setIsVerificationCodeExpired: (state, actions) => {
      state.isVerificationCodeExpired = actions.payload;
    },
    resetProfessionals: (state) => {
      state.professionals = [];
    },
  },
  extraReducers: (builder) => {
    //professionals fetch
    builder.addCase(fetchProfessionals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.professionals = [];
      state.error = action.payload as string;
    });
    builder.addCase(fetchProfessionals.fulfilled, (state, action) => {
      state.professionals = action.payload.entities;
      state.total = action.payload.total!;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.isLoading = false;
    });
    //professionals fetch for autosuggestion
    builder.addCase(fetchProfessionalsForAutosuggest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchProfessionalsForAutosuggest.rejected,
      (state, action) => {
        state.isLoading = false;
        state.professionalsAutosuggest = [];
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      fetchProfessionalsForAutosuggest.fulfilled,
      (state, action) => {
        state.professionalsAutosuggest = action.payload.entities;
        state.isLoading = false;
      },
    );
    //professionals delete
    builder.addCase(deleteProfessionals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteProfessionals.fulfilled, (state, action) => {
      state.deletedSuccess = true;
      state.isLoading = false;
    });
    //professional check code expiration dto
    builder.addCase(checkProfessionalVerificationCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      checkProfessionalVerificationCode.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(checkProfessionalVerificationCode.fulfilled, (state) => {
      state.isLoading = false;
    });
    //professional create password
    builder.addCase(createProfessionalPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProfessionalPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isCreatePassword = false;
    });
    builder.addCase(createProfessionalPassword.fulfilled, (state) => {
      state.isLoading = false;
      state.isCreatePassword = true;
    });
    //professional create password
    builder.addCase(fetchSubscribedPatients.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSubscribedPatients.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSubscribedPatients.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  selectProfessional,
  setIsCreatePassword,
  setIsVerificationCodeExpired,
  resetProfessionals,
} = professionalsSlice.actions;

export default professionalsSlice.reducer;
