import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  min-width: 310px;
  width: 310px;
  padding: 20px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue50 : theme.color.grey50};
  border: ${({ theme, isActive }) =>
    `1px solid ${isActive ? theme.color.blue400 : 'transparent'}`};
  border-radius: 12px;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};

  &:hover {
    box-shadow: ${({ isActive }) =>
      isActive ? 'none' : '0 0 3px 0 rgba(11, 37, 80, 0.4)'};
  }
  > div:last-of-type {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
  }
`;

export const PackageInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 500;
  font-size: 16px;
  align-items: flex-start;
  justify-content: space-between;

  label {
    margin-right: 6px;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.color.grey900};
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.grey900};
    margin-top: 4px;
    height: 40px;
  }
`;

export const MainPrice = styled.div`
  font-family: Mulish;
  color: ${({ theme }) => theme.color.grey800};
  font-size: 14px;
  line-height: 16.8px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;

  > span {
    font-family: AcidGrotesk-Bold;
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-right: 4px;
  }
`;

export const AdditionalPrice = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.grey700};
  font-size: 12px;
  line-height: 14.4px;
`;

export const PriceSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: ${({ theme }) => theme.color.grey100};
  border-radius: 8px;
  padding: 8px 12px;
`;

export const Switcher = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 19.6px;
`;

export const PeriodName = styled.div<Props>`
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
`;

export const SwitchersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin-top: 12px;

  button {
    padding: 8px 20px;
    height: 38px;
  }
`;

export const ErrorMessage = styled.p`
  color: ${commonColors.error1};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.07px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const MessageStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2px;

  > span {
    color: ${commonColors.error2};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  > div {
    display: flex;
    flex-direction: column;
    p {
      color: ${commonColors.grey600};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
`;
