export enum Period {
  month = 'month',
  year = 'year',
}

export interface StripePriceTierDto {
  unitAmountDecimal: string;
  upTo: number;
}

export interface StripePriceResponseDto {
  id: string;
  currency: string;
  unitAmountDecimal: string;
  recurringInterval: string;
  tiers?: StripePriceTierDto[];
}
