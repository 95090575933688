import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getClientSecret } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { getToken } from 'applicaiton/sessionStorage/auth';

import {
  CountryDropdown,
  Input,
  Loader,
  MapBoxAutocompleteInput,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { Context } from 'common/types/mapBox';
import { SetupIntent } from 'common/types/stripe';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  SupportedCountries,
  SupportedCountriesDto,
} from 'common/types/countries';

import { useBillingInfoSchema } from 'features/feature-manage-plan/hooks/useBillingInfoSchema';

import ButtonGroup from '../ButtonGroup';

import { BillingInfoProps, Location, FormData } from './modules';
import {
  BillingInfoStyled,
  IBAN_STYLE,
  IbanSection,
  IbanStyled,
} from './styles';
import { setBillingInfo } from 'applicaiton/store/reducers/ClinicOwnerSubscription/ClinicOwnerSubscriptionSlice';

const defaultLocation = {
  city: '',
  country: '',
  postal_code: '',
  line1: '',
};

const BillingInfo: FC<BillingInfoProps> = ({ setStep, setSetupIntent }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const { billingInfo } = useAppSelector(
    (state) => state.clinicOwnerSubscription,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [sepaClientSecret, setSepaClientSecret] = useState('');
  const [country, setCountry] = useState<SupportedCountriesDto>({
    id: '',
    code: SupportedCountries.Portugal,
    name: '',
  });
  const [location, setLocation] = useState<Location>(
    billingInfo?.location || defaultLocation,
  );
  const billingInfoSchema = useBillingInfoSchema();

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(billingInfoSchema),
  });

  useEffect(() => {
    if (billingInfo) {
      setValue('companyName', billingInfo.companyName);
      setCountry(billingInfo.country);
      setLocation(billingInfo.location);
    }
  }, [billingInfo]);

  useEffect(() => {
    const token = getToken();
    if (token) {
      dispatch(getClientSecret(token)).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setSepaClientSecret(data.payload.sepaClientSecret);
        }
      });
    }
  }, []);

  const handleSetAddress = (context: Context) => {
    setLocation({
      city: context.place.name,
      country: context.country.country_code,
      postal_code: context.postcode.name,
      line1: context.address?.name || context.street?.name || '',
    });
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: country.code,
    style: IBAN_STYLE,
  };

  const handleSubmit = async () => {
    const ibanElement = elements?.getElement(IbanElement);
    if (!stripe || !elements || !ibanElement) {
      return;
    }
    setIsLoading(true);
    const { setupIntent } = await stripe.confirmSepaDebitSetup(
      sepaClientSecret,
      {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: {
            name: getValues('companyName'),
            email: currentClinicOwner?.user?.email || '',
            address: location,
            phone: currentClinicOwner?.phone || '',
          },
        },
      },
    );
    setIsLoading(false);
    if (setupIntent) {
      dispatch(
        setBillingInfo({
          companyName: getValues('companyName'),
          location,
          country,
        }),
      );
      setSetupIntent((setupIntent as SetupIntent) || null);
      setStep(3);
    }
  };

  const handleChangeCountry = (value: SupportedCountriesDto) => {
    if (country.code !== value.code) {
      setCountry(value);
      setLocation(defaultLocation);
    }
  };

  const getAddressStreet = () => {
    if (location) {
      const address = [
        location.line1 || '',
        location.postal_code || '',
        location.city || '',
        location.country || '',
      ]
        .filter((item) => !!item)
        .join(' ');
      return address;
    }
    return '';
  };

  const disabledButton = !location || !getValues('companyName');

  const handleBackButton = () => {
    setStep(1);
  };

  return (
    <>
      {isLoading && <Loader />}
      <BillingInfoStyled>
        <section>
          <h2>
            {t('clinicRegistration.billing_information.personal_info_SEPA')}
          </h2>
          <div>
            <Input
              id="companyName"
              label={
                t('clinicRegistration.billing_information.company_name') || ''
              }
              type="text"
              placeholder={
                t(
                  'clinicRegistration.billing_information.company_name_placeholder',
                ) || ''
              }
              errorMessage={errors.companyName?.message}
              register={register}
            />
            <CountryDropdown
              label={t('country_of_practice') || ''}
              placeholder={t('select_country_of_practice')}
              country={country}
              onChange={handleChangeCountry}
            />
            <MapBoxAutocompleteInput
              id={'address'}
              label={t('clinicRegistration.billing_information.address') || ''}
              defaultValue={getAddressStreet()}
              setLocation={handleSetAddress}
              countryIso={country.code}
            />
            <IbanSection>
              <label>IBAN</label>
              <IbanStyled>
                <IbanElement options={IBAN_ELEMENT_OPTIONS} />
              </IbanStyled>
            </IbanSection>
          </div>
        </section>
        <ButtonGroup>
          <PrimaryButton
            disabled={disabledButton}
            type="button"
            onClick={handleSubmit}
          >
            {t('clinicRegistration.continue')}
          </PrimaryButton>
          <SecondaryButton
            onClick={handleBackButton}
            disabled={false}
            type="button"
          >
            {t('clinicRegistration.back')}
          </SecondaryButton>
        </ButtonGroup>
      </BillingInfoStyled>
    </>
  );
};

export default BillingInfo;
