export enum PackFeatures {
  basicPack = 'BASIC_PACK',
  clinicFullPack = 'CLINIC_FULL_PACK',
  privateDoctorPack = 'PRIVATE_DOCTOR_PACK',
  invoicingFeature = 'INVOICING_FEATURE',
}

export enum StripePromoCodeError {
  PROMO_CODE_NOT_FOUND_OR_INACTIVE = 'PROMO_CODE_NOT_FOUND_OR_INACTIVE',
}
