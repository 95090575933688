import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Specialization } from '@docbay/schemas';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { Dropdown, DropdownClinics } from 'common/components';
import {
  DropdownSection,
  DropdownList,
  Specializations,
  StyledRestartIcon,
  AppointmentTypesWrapper,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchSpecializations } from 'applicaiton/store/reducers/Specializations/ActionCreators';
import { Props } from './modules';
import DropdownAppointmentTypes from 'common/components/DropdownAppointmentTypes';
import { AppointmentTypeOption } from 'common/components/DropdownAppointmentTypes/models';
import { fetchAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

const ClinicDropdownSection: FC<Props> = ({
  getValues,
  clearErrors,
  watch,
  setValue,
  errors,
  arrayIndex,
  selectedClinics,
  hideClinicDropdown,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { specializations } = useAppSelector((state) => state.specializations);
  const { appointmentTypes } = useAppSelector(
    (state) => state.appointmentTypes,
  );
  const { clinics } = useAppSelector((state) => state.clinics);
  const [isOpenAppointmentTypes, setIsOpenAppointmentTypes] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [addedSpecializations, setAddedSpecializations] = useState<string[]>([
    '',
  ]);
  const userRole = getUserRole();
  const userId = getUserId();
  const isUserRoleProfessional = userRole === UserRoles.professional;

  const canManageAppointmentType = useMemo(() => {
    const currentClinic = clinics.find(
      (clinic) => clinic.id === watch('clinic'),
    );
    const currentClinicOwnerId = currentClinic?.owner?.id;
    const { hasAppointmentTypeConfigurationPermission } =
      featuresPermissions(currentClinicOwnerId);

    return hasAppointmentTypeConfigurationPermission;
  }, [watch('clinic'), clinics]);

  const [addedAppointmentTypes, setAddedAppointmentTypes] = useState<
    AppointmentTypeOption[]
  >([]);

  useEffect(() => {
    dispatch(fetchSpecializations());
    setAddedSpecializations(['']);
  }, [arrayIndex]);

  useEffect(() => {
    if (getValues('specializationsToAdd').length) {
      setAddedSpecializations(getValues('specializationsToAdd'));
    }
  }, [watch('specializationsToAdd')]);

  const optionList = useMemo(() => {
    if (!appointmentTypes) return [];

    const appointmentList = appointmentTypes;

    const options = appointmentList.map((item) => ({
      label: item?.title || '',
      value: item?.id,
      colorCode: item?.colorCode,
      duration: item?.durationInMinutes,
      specializationIds: item?.specialization?.id,
    }));

    return options;
  }, [appointmentTypes]);

  useEffect(() => {
    const appointmentTypes = getValues('appointmentTypes');
    const appointmentTypesSelected = optionList.filter((option: any) => {
      const appointmentTypeAdded = appointmentTypes.find(
        (item: string) => item === option.value,
      );
      return appointmentTypeAdded;
    });

    setAddedAppointmentTypes(appointmentTypesSelected);
  }, [watch('appointmentTypes'), optionList]);

  const specializationsOptions = useMemo(() => {
    const currentLanguage = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${currentLanguage.toUpperCase()}` as
      | 'name_EN'
      | 'name_PT';

    const options = specializations.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  const handleAddSpecialization = () => {
    setAddedSpecializations((prev) => [...prev, '']);
  };

  const handleRemoveSpecialization = (index: number) => {
    const specializationsToAdd = getValues('specializationsToAdd');
    changeAppointmentTypes(specializationsToAdd[index]);

    setValue(
      'specializationsToAdd',
      specializationsToAdd.filter((item: string, i: number) => i !== index),
    );
  };

  const handleEditSpecializations = (index: number, value: string) => {
    changeAppointmentTypes(addedSpecializations[index]);

    const editedSpecializations = addedSpecializations.map((item, i) => {
      if (index === i) {
        return value;
      }
      return item;
    });
    setValue('specializationsToAdd', editedSpecializations);
    clearErrors('specializationsToAdd');
  };

  const changeAppointmentTypes = (specializationId: string) => {
    const appointmentTypes = addedAppointmentTypes.filter(
      (item) => item?.specializationIds !== specializationId,
    );
    setAddedAppointmentTypes(appointmentTypes);
  };

  const canBeAdded = useMemo(() => {
    return !addedSpecializations.includes('');
  }, [addedSpecializations]);

  const handleClinicChange = (id: string) => {
    setValue('clinic', id);
    clearErrors('clinic');
  };

  const handleAppointmentTypesChange = (
    appointments: AppointmentTypeOption[],
  ) => {
    setAddedAppointmentTypes(appointments as AppointmentTypeOption[]);

    const appointmentTypeIds = appointments.map((item) => item.value);

    setValue('appointmentTypes', appointmentTypeIds);
    appointments.length && clearErrors('appointmentTypes');
  };

  const getSpecializationsIds = () => {
    return getValues('specializationsToAdd')
      .filter((item: string) => !!item)
      .map((item: string) => String(item))
      .join();
  };

  const handleRefreshAppointmentTypes = () => {
    const clinicId = getValues('clinic');
    const specializationIds = getSpecializationsIds();

    setIsPending(true);

    if (clinicId && specializationIds) {
      dispatch(
        fetchAppointmentTypes({
          clinicId: clinicId,
          specializationIds: specializationIds,
          ...(isUserRoleProfessional ? { professionalIds: userId! } : {}),
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setIsOpenAppointmentTypes(true);
          setIsPending(false);
        }
      });
    }
  };

  return (
    <DropdownSection>
      {!hideClinicDropdown ? (
        <DropdownClinics
          isError={!!errors?.clinic?.message}
          value={watch('clinic')}
          label={t('professional_profile.select_clinic')!}
          placeholder={t('professional_profile.select_clinic')!}
          onChange={(value) => {
            handleClinicChange(String(value));
          }}
          errorMessage={errors?.clinic?.message}
          notIncludingClinics={selectedClinics}
        />
      ) : (
        <></>
      )}
      <DropdownList isError={errors?.specializationsToAdd?.message}>
        <label>{t('professional_profile.specialization')}</label>
        <Specializations>
          {addedSpecializations.map((item, index) => (
            <div key={index}>
              <Dropdown
                id={'specializationsToAdd'}
                value={item}
                placeholder={t(
                  'professional_profile.specialization_placeholder',
                )}
                onChange={(value) => {
                  if (!Array.isArray(value)) {
                    handleEditSpecializations(index, String(value.value));
                  }
                }}
                options={specializationsOptions}
                withSearch={true}
                errorMessage={errors?.specializationsToAdd?.message}
              />
              {index ? (
                <button onClick={() => handleRemoveSpecialization(index)}>
                  <CloseIcon />
                </button>
              ) : (
                ''
              )}
            </div>
          ))}
        </Specializations>
        <button
          disabled={!canBeAdded}
          type="button"
          onClick={() => handleAddSpecialization()}
        >
          <AddIcon />
          <h2>{t('add_another')}</h2>
        </button>
      </DropdownList>
      {canManageAppointmentType && (
        <AppointmentTypesWrapper>
          <DropdownAppointmentTypes
            label={t('appointment_types.type_of_appointments') || ''}
            value={addedAppointmentTypes}
            onChange={(value) => {
              handleAppointmentTypesChange(value as AppointmentTypeOption[]);
            }}
            clinicId={getValues('clinic')}
            specializationIds={getSpecializationsIds()}
            withSearch={false}
            isMulti={true}
            disabled={!addedSpecializations[0].length}
            errorMessage={errors?.appointmentTypes?.message}
            isOpen={isOpenAppointmentTypes}
          />
          <StyledRestartIcon
            isPending={isPending}
            onClick={handleRefreshAppointmentTypes}
          />
        </AppointmentTypesWrapper>
      )}
    </DropdownSection>
  );
};

export default ClinicDropdownSection;
