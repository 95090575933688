import React, { FC, useEffect } from 'react';
import { Wrapper, AddNewProfessional, WarningMessage, Title } from './styles';
import { Header, LayoutComponent } from './components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/info.svg';
import {
  resetProfileChanges,
  selectExistingProfessional,
  setIsClinicOwnerProfile,
  setShowClinicListModal,
} from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { getSubscriptions } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { PrimaryButton } from 'common/components';

const ProfessionalAdd: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isClinicOwnerProfile } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );
  const { isExistDeletedDoctor } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );
  const { isPrivateDoctor } = useClinicOwner();

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setHasUnsavedChanges(false));
      dispatch(setIsClinicOwnerProfile(false));
      dispatch(resetProfileChanges());
      dispatch(selectExistingProfessional(null));
    };
  }, []);

  const handleOpenRestoreModal = () => {
    dispatch(setShowClinicListModal(true));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addNewProfessionalMessage = () => {
    return isClinicOwnerProfile ? (
      <WarningMessage>
        {t('clinic_owner.adding_my_profile')}
        {!isPrivateDoctor ? (
          <div>
            <InfoIcon />
            {t('clinic_owner.profile_infoTex1')}
            <span>&#171;{t('clinic_owner.profile_infoTex2')}&#187;</span>
            {t('clinic_owner.profile_infoTex3')}
          </div>
        ) : (
          <></>
        )}
      </WarningMessage>
    ) : (
      <Title>
        {t('professional_profile.add_professional')}
        {isExistDeletedDoctor && (
          <PrimaryButton onClick={handleOpenRestoreModal}>
            {t('professional_profile.restore_professional')}
          </PrimaryButton>
        )}
      </Title>
    );
  };

  return (
    <Wrapper id="professional-add">
      <Header isClinicOwnerProfile={isClinicOwnerProfile} />
      <AddNewProfessional>{addNewProfessionalMessage()}</AddNewProfessional>
      <LayoutComponent isClinicOwnerProfile={isClinicOwnerProfile} />
    </Wrapper>
  );
};

export default ProfessionalAdd;
