import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  getCustomerBillingActivationLink,
  getCustomerBillingLink,
  getCustomerBillingUpdateLink,
  getCustomerPortalLink,
} from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { CreditCardIcon, VideoCameraIcon } from 'applicaiton/assets';
import { PathNames } from 'applicaiton/routes';
import { getCurrentSubscription } from 'applicaiton/store/reducers/ClinicOwnerSubscription/ActionCreators';

import Loader from 'common/components/Loader';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

import { Wrapper, CardStyled, Content, Badges, StyledLink } from './styles';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserRole } from 'applicaiton/sessionStorage/auth';

const BillingsList: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { customerPortalLink, currentClinicOwner, customerBillingLink } =
    useAppSelector((state) => state.clinicOwnerSlice);
  const { subscriptionPackage } = useAppSelector(
    (state) => state.clinicOwnerSubscription,
  );
  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);
  const { hasTeleconsultationPermission } = featuresPermissions();

  const subscriptionName = subscriptionPackage
    ? subscriptionPackage.productName
    : t('clinic_billing.current_plan.no_subscription_plan');

  const subscriptionEndDate = dayjs(
    subscriptionPackage?.purchasedSubscription?.currentPeriodEnd,
  )
    .locale(i18n.language)
    .format('MMM DD, YYYY');

  useEffect(() => {
    if (currentClinicOwner) {
      const getLinks = async () => {
        dispatch(getCurrentSubscription());
        dispatch(getCustomerPortalLink());
        dispatch(getCustomerBillingLink(currentClinicOwner.id!)).then(
          (data) => {
            if (data.meta.requestStatus === 'fulfilled') {
              if (data.payload) {
                dispatch(getCustomerBillingUpdateLink());
              } else {
                dispatch(getCustomerBillingActivationLink());
              }
            }
          },
        );
      };

      getLinks();
    }
  }, [currentClinicOwner]);

  if (!customerPortalLink?.length || !customerBillingLink?.length) {
    return <Loader />;
  }

  return (
    <Wrapper>
      {customerPortalLink && (
        <CardStyled>
          <Content>
            <CreditCardIcon />
            <h2>{t('clinic_billing.billing_account.title')}</h2>
            <p>{t('clinic_billing.billing_account.description')}</p>
          </Content>
          <StyledLink to={customerPortalLink} target="_blank">
            {t('clinic_owner.billing.button_text')}
          </StyledLink>
        </CardStyled>
      )}
      {customerBillingLink && hasTeleconsultationPermission && (
        <CardStyled>
          <Content>
            <VideoCameraIcon />
            <h2>{t('clinic_billing.teleconsultation_payment.title')}</h2>
            <p>{t('clinic_billing.teleconsultation_payment.description')}</p>
          </Content>
          <StyledLink to={customerBillingLink} target="_blank">
            {t('clinic_billing.teleconsultation_payment.action_text')}
          </StyledLink>
        </CardStyled>
      )}
      {isClinicOwnerRole && (
        <CardStyled>
          <Content>
            <Badges>
              <CreditCardIcon />
              <p>{t('clinic_billing.current_plan.title')}</p>
            </Badges>
            <h2>{subscriptionName}</h2>
            {subscriptionPackage?.purchasedSubscription?.currentPeriodEnd && (
              <p>
                {t('clinic_billing.current_plan.text_1')}{' '}
                {subscriptionPackage.productName}{' '}
                {t('clinic_billing.current_plan.text_2')}{' '}
                <b>{subscriptionEndDate}</b>
              </p>
            )}
          </Content>
          <StyledLink to={PathNames.managePlan}>
            {subscriptionPackage
              ? t('clinic_billing.current_plan.change_plan')
              : t('clinic_billing.current_plan.select_plan')}
          </StyledLink>
        </CardStyled>
      )}
    </Wrapper>
  );
};

export default BillingsList;
