import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import {
  ImageIcon,
  PdfIcon,
  DocumentIcon,
} from 'applicaiton/assets/document-types';
import { DeleteIcon, DownloadIcon, ExpandMoreIcon } from 'applicaiton/assets';
import { ChatsMode } from 'applicaiton/constants/chats';

import { PreviewModal } from 'common/components';
import useOnClickOutside from 'common/hooks/useClickOutside';

import { ChatsAPI } from 'integration/api/chats';

import { MessageDocumentProps } from './module';
import {
  MessageStyled,
  ImageStyled,
  MoreButton,
  ImageMenuStyled,
  LoaderContainer,
} from './styles';

const imageExtensions = ['jpg', 'jpeg', 'png'];

const MessageDocument: FC<MessageDocumentProps> = ({
  message,
  mode,
  messageTime,
  handleOpenMenu,
  onDelete,
  onDownload,
}) => {
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const isImage = imageExtensions.includes(message?.document?.extension!);

  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  const getImage = async () => {
    if (message.document?.id) {
      setIsLoading(true);
      const response = await ChatsAPI.downloadDocument(message.id);
      if (response.data) {
        const imageUrl = URL.createObjectURL(response.data);
        setImage(imageUrl);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isImage) {
      getImage();
    }
  }, []);

  const openPreviewImage = (e: any) => {
    const id = e.target.id;
    if (id !== 'icon' && id !== 'menu') {
      setPreviewImage(true);
    }
  };
  const closePreviewImage = () => {
    setPreviewImage(false);
  };

  const onShowMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const getCurrentIcon = () => {
    if (isImage) {
      return <ImageIcon />;
    } else if (message?.document?.extension.includes('pdf')) {
      return <PdfIcon />;
    } else {
      return <DocumentIcon />;
    }
  };

  return (
    <>
      <MessageStyled
        disabled={mode === ChatsMode.ArchivedChats}
        className={message.isOwnMessage ? 'own-message' : ''}
        onClick={isImage ? openPreviewImage : handleOpenMenu}
        emergency={message.emergency}
      >
        {isLoading ? (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        ) : (
          <>
            {isImage ? (
              <ImageStyled>
                <MoreButton id="icon" type="button" onClick={onShowMenu}>
                  <ExpandMoreIcon id="icon" />
                </MoreButton>
                <img src={image} alt={message.document?.originalName} />
                {showMenu && (
                  <ImageMenuStyled ref={menuRef} id="menu">
                    <button
                      type="button"
                      id="menu"
                      onClick={() => onDownload(message)}
                    >
                      <DownloadIcon />
                      {t('download')}
                    </button>
                    {message.isOwnMessage && (
                      <button
                        type="button"
                        id="menu"
                        onClick={() => onDelete(message)}
                      >
                        <DeleteIcon />
                        {t('delete')}
                      </button>
                    )}
                  </ImageMenuStyled>
                )}
              </ImageStyled>
            ) : (
              <p className={'message-document'}>
                {getCurrentIcon()}
                {message.document?.originalName}
              </p>
            )}
          </>
        )}
        <p className={'message-time'}>{messageTime}</p>
      </MessageStyled>
      {previewImage && (
        <PreviewModal
          open={previewImage}
          onClose={closePreviewImage}
          image={image}
        />
      )}
    </>
  );
};

export default MessageDocument;
