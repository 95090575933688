import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

export const HeaderStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  > div {
    display: flex;
    flex-direction: column;

    h1 {
      color: ${commonColors.grey900};
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 39.2px;
      letter-spacing: 0.28px;
      text-transform: capitalize;
    }

    p {
      margin-top: 8px;
      color: ${commonColors.grey600};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22.4px;
    }
  }

  button {
    min-width: 173px;
    width: fit-content;
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  border-radius: 12px;
  border-collapse: separate;
  border-radius: 12px;
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
  border: 1px solid ${({ theme }) => theme.color.grey200};

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        color: ${({ theme }) => theme.color.grey600};
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        background: ${({ theme }) => theme.color.grey50};
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

        &:first-of-type {
          width: 240px;
        }
        &:last-of-type {
          width: 230px;
        }
      }
    }
  }
  > tbody {
    > tr {
      &:last-child {
        > td {
          &:first-child {
            border-bottom-left-radius: 12px;
          }
          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
`;
