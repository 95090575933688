import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { ChevronLeftIcon } from 'applicaiton/assets';
import { PathNames } from 'applicaiton/routes';
import { ReactComponent as HelpIcon } from 'applicaiton/assets/help.svg';
import { ReactComponent as SepaIcon } from 'applicaiton/assets/sepa.svg';
import { DocBaySupportLink } from 'applicaiton';

import { SecondaryButton } from 'common/components';

import { HeaderProps } from './models';
import {
  Wrapper,
  BackButton,
  HeaderTitleStyled,
  LinkStyled,
  SepaStyled,
} from './styles';

const Header: FC<HeaderProps> = ({ step }) => {
  const { t } = useTranslation();

  const title = i18next.exists(`change_plan.header.step${step}.title`)
    ? t(`change_plan.header.step${step}.title`)
    : '';

  const description = i18next.exists(
    `change_plan.header.step${step}.description`,
  )
    ? t(`change_plan.header.step${step}.description`) || ''
    : '';

  const firstStep = step === 1;

  return (
    <Wrapper>
      {firstStep ? (
        <BackButton to={PathNames.clinicBilling}>
          <ChevronLeftIcon />
          {t('change_plan.back_to_billing')}
        </BackButton>
      ) : (
        <HeaderTitleStyled>
          <div>
            <h1>{title}</h1>
            <SepaStyled>
              <SepaIcon />
              <p>{description}</p>
            </SepaStyled>
          </div>
          <LinkStyled to={DocBaySupportLink} target="_blank">
            <SecondaryButton styleType={'color'} type="button">
              <HelpIcon />
              {t('clinicRegistration.header.help')}
            </SecondaryButton>
          </LinkStyled>
        </HeaderTitleStyled>
      )}
    </Wrapper>
  );
};

export default Header;
