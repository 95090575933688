import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPackageResponse } from '@docbay/schemas';

import {
  currentClinicOwnerRegistration,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';
import { getSubscriptionsAction } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { setIsSubscriptionsFree } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';

import { Loader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import { usePrivateDoctorRegistration } from 'features/feature-clinic-registration/hooks/usePrivateDoctorRegistration';
import { PackFeatures } from 'features/feature-clinic-registration/constants';

import Package from '../Package';
import ButtonGroup from '../ButtonGroup';

import { SectionStyled, SubscriptionsWrapper } from './styles';

interface PackageListProps {
  setStep: (value: number) => void;
}

const PackageList: FC<PackageListProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const { subscriptionPackages } = currentClinicOwnerRegistration();
  const [packages, setPackages] = useState<{
    [key: string]: SubscriptionPackageResponse[];
  }>(subscriptionPackages || {});
  const [selectedPackage, setSelectedPackage] = useState('');

  const { isPrivateDoctor, setPrivateDoctor } = usePrivateDoctorRegistration();

  useEffect(() => {
    dispatch(getSubscriptionsAction());
  }, [dispatch]);

  const filteredSubscriptions = useMemo(() => {
    return subscriptions?.filter(
      (item) => String(item.type) !== PackFeatures.invoicingFeature,
    );
  }, [subscriptions]);

  const handleSetPackage = (
    key: string,
    value: number,
    selectedPackage: SubscriptionPackageResponse,
  ) => {
    if (value < 0) return;
    const createArrayOfPackages = Array.from(Array(value).keys()).map(
      () => selectedPackage,
    );

    setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {
      [key]: createArrayOfPackages,
    });
    setPackages({ [key]: createArrayOfPackages });
  };

  const packagesCount = Object.values(packages).flat(1).length;

  const disableButton = !packagesCount && !isPrivateDoctor;

  useEffect(() => {
    if (!isPrivateDoctor) {
      return;
    }
    if (packagesCount > 1) {
      setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {});
      setClinicOwnerRegistrationSessionStorage('isInvoicing', false);
      setClinicOwnerRegistrationSessionStorage('isYearlyPeriod', false);
      setPackages({});
    }
    if (subscriptions.length === 1) {
      handleSetPackage('quantity1', 1, subscriptions[0]);
    }
  }, [isPrivateDoctor, subscriptions]);

  const handleChoosePackage = (id: string) => {
    const currentPack = subscriptions.find((pack) => pack.productId === id);
    const isPrivateDoctorPack =
      String(currentPack?.type) === PackFeatures.privateDoctorPack;
    const price = isPrivateDoctorPack
      ? currentPack?.prices[0]
      : currentPack?.prices[0].tiers![0];
    const shouldPay = Number(price?.unitAmountDecimal) > 0;

    dispatch(setIsSubscriptionsFree(!shouldPay));
    if (currentPack?.productId !== selectedPackage) {
      setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {});
      setSelectedPackage(id);
      setPackages({});
      setClinicOwnerRegistrationSessionStorage('isInvoicing', false);
      setClinicOwnerRegistrationSessionStorage('isYearlyPeriod', false);
    }

    // @ts-ignore
    setPrivateDoctor(currentPack.privateDoctor);
  };

  useEffect(() => {
    const packages =
      JSON.parse(sessionStorage.getItem('clinicOwnerRegistration') || '{}') ||
      {};

    const keyName = packages?.subscriptionPackages
      ? Object.keys(packages.subscriptionPackages)[0]
      : '';

    if (
      packages?.subscriptionPackages &&
      packages?.subscriptionPackages[keyName]?.length
    ) {
      const currentSubscription = filteredSubscriptions?.find(
        (subscription) =>
          subscription.productId ===
          packages?.subscriptionPackages[keyName][0].productId,
      );

      if (currentSubscription) {
        handleChoosePackage(currentSubscription!.productId);
        handleSetPackage(
          keyName,
          packages?.subscriptionPackages[keyName]?.length,
          currentSubscription!,
        );
      }
    }
  }, [filteredSubscriptions]);

  return (
    <SectionStyled>
      {isLoading && <Loader />}
      <SubscriptionsWrapper>
        {filteredSubscriptions?.map((item, index) => (
          <Package
            key={item.productId}
            id={item.productId}
            quantity={packages[`quantity${index + 1}`]?.length || 0}
            handleSetQuantity={(value) => {
              handleChoosePackage(item.productId);
              handleSetPackage(`quantity${index + 1}`, value, item);
            }}
            title={item.name}
            onClick={() => {
              handleChoosePackage(item.productId);
              item.privateDoctor &&
                handleSetPackage(`quantity${index + 1}`, 1, item);
            }}
            isActive={selectedPackage === item.productId}
            isPrivateDoctor={!!item?.privateDoctor}
            packageType={String(item.type)}
            prices={item.prices}
          />
        ))}
      </SubscriptionsWrapper>
      <ButtonGroup>
        <PrimaryButton onClick={() => setStep(1)} disabled={disableButton}>
          {t('clinicRegistration.continue')}
        </PrimaryButton>
      </ButtonGroup>
    </SectionStyled>
  );
};

export default PackageList;
