import React, { FC, useEffect, useState } from 'react';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'common/hooks/redux';
import { getMandateInfoAction } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const SEPAInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setupIntent } = currentClinicOwnerRegistration();
  const [mandateReference, setMandateReference] = useState('');

  useEffect(() => {
    if (!setupIntent?.id) return;
    const getMandate = async () => {
      const { payload } = await dispatch(getMandateInfoAction(setupIntent.id!));

      setMandateReference(payload.payment_method_details.sepa_debit.reference);
    };
    getMandate();
  }, []);

  return (
    <SectionStyled>
      <p>{t('clinicRegistration.payment_process.general_info_sepa')}</p>
      <div>
        <div>
          <h2>{t('date')}</h2>
          <p>{dayjs().format('DD/MM/YYYY')}</p>
        </div>
        <div>
          <h2>{t('clinicRegistration.payment_process.type_of_payment')}</h2>
          <p>{t('clinicRegistration.payment_process.recurring_payment')}</p>
        </div>
      </div>
      <div>
        <div>
          <h2>{t('debtor')}</h2>
          <p>{t('clinicRegistration.payment_process.medical_marketplace')}</p>
        </div>
        <div>
          <h2>{t('clinicRegistration.payment_process.mandate_reference')}</h2>
          <p>{mandateReference}</p>
        </div>
      </div>
    </SectionStyled>
  );
};

export default SEPAInfo;
