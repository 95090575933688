import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPackageResponse } from '@docbay/schemas';

import { ReactComponent as QuantityIcon } from 'applicaiton/assets/quantity.svg';

import { useAppSelector } from 'common/hooks/redux';

import { getCurrentPeriodPrice } from 'features/feature-manage-plan/helpers/getCurrentPeriodPrice';
import { PackFeatures } from 'features/feature-clinic-registration/constants';

import { PaymentOrderProps } from './modules';
import { PaymentOrderStyled, SummarySectionStyled } from './styles';

const PaymentOrder: FC<PaymentOrderProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const { selectedPackages, isYearlySubscription, selectedInvoicingPackage } =
    useAppSelector((state) => state.clinicOwnerSubscription);
  const { promoCodeData } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const packages = selectedPackages;
  const currentPackages = useMemo(() => {
    if (!packages.length) return [];
    return [packages[0]];
  }, [packages]);

  const isOnlineServicePackage = useMemo(() => {
    return packages.some(
      (item) => String(item.type) === PackFeatures.privateDoctorPack,
    );
  }, [packages]);

  const invoicingSubscriptionPrices = useMemo(() => {
    if (!selectedInvoicingPackage?.prices?.length) return 0;

    const currentPrice = getCurrentPeriodPrice(
      selectedInvoicingPackage?.prices || [],
      isYearlySubscription,
    );

    return Number(currentPrice?.unitAmountDecimal || 0) / 100;
  }, [selectedInvoicingPackage, isYearlySubscription]);

  const firstDoctorPrice = useMemo(() => {
    const currentPrice = getCurrentPeriodPrice(
      packages[0]?.prices || [],
      isYearlySubscription,
    );

    const price = currentPrice?.tiers?.length
      ? Number(currentPrice.tiers[0].unitAmountDecimal)
      : Number(currentPrice?.unitAmountDecimal || 0);

    return price / 100;
  }, [packages, isYearlySubscription]);

  const nextDoctorPrice = useMemo(() => {
    const currentPrice = getCurrentPeriodPrice(
      packages[0]?.prices || [],
      isYearlySubscription,
    );

    const price = currentPrice?.tiers?.length
      ? Number(currentPrice.tiers[1].unitAmountDecimal)
      : Number(currentPrice?.unitAmountDecimal || 0);

    return price / 100;
  }, [packages, isYearlySubscription]);

  const packagesSum = useMemo(() => {
    if (!packages.length) return 0;
    if (isOnlineServicePackage) {
      return invoicingSubscriptionPrices;
    }

    return packages.length === 1
      ? firstDoctorPrice
      : nextDoctorPrice * (packages.length - 1) + firstDoctorPrice;
  }, [packages, firstDoctorPrice, nextDoctorPrice, isOnlineServicePackage]);

  const getQuantity = (item: SubscriptionPackageResponse) => {
    const allPackages = [
      ...selectedPackages,
      ...(selectedInvoicingPackage ? [selectedInvoicingPackage] : []),
    ];
    const quantity = allPackages.filter(
      (pack) => pack.productId === item.productId,
    ).length;

    return Number(quantity);
  };

  const summary = packagesSum;

  return (
    <PaymentOrderStyled>
      <div>
        <h1>{t('clinicRegistration.payment_process.my_order')}</h1>
        <button type="button" onClick={() => setStep(1)}>
          {t(
            'clinicRegistration.payment_process.change_subscription_selection',
          )}
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentPackages.map((item: SubscriptionPackageResponse) => {
            if (!item) return <></>;
            return (
              <tr key={item.productId}>
                <td>
                  <div>
                    <p>{item?.name || ''}</p>
                    <span>
                      {t('billing_period')}:{' '}
                      {isYearlySubscription ? t('annually') : t('monthly')}
                    </span>
                  </div>
                </td>
                <td>
                  {isOnlineServicePackage ? (
                    !!invoicingSubscriptionPrices && (
                      <div
                        className={'payment-info'}
                      >{`invoicing: €${invoicingSubscriptionPrices}`}</div>
                    )
                  ) : (
                    <>
                      <div
                        className={'payment-info'}
                      >{`€${firstDoctorPrice}/${t(
                        'price_per_first',
                      )} + €${nextDoctorPrice}/${t(
                        'price_per_additional',
                      )}`}</div>
                      {!!invoicingSubscriptionPrices && (
                        <div className={'payment-info'}>{`${t(
                          'invoicing',
                        )}: €${invoicingSubscriptionPrices}`}</div>
                      )}
                    </>
                  )}
                </td>
                <td>
                  <div>
                    <div>
                      <QuantityIcon />
                      <span>{getQuantity(item)}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    €
                    {isOnlineServicePackage
                      ? invoicingSubscriptionPrices
                      : packagesSum + invoicingSubscriptionPrices}
                  </div>
                </td>
              </tr>
            );
          })}

          <tr>
            <td colSpan={4}>
              Subtotal: €
              {isOnlineServicePackage
                ? invoicingSubscriptionPrices
                : packagesSum + invoicingSubscriptionPrices}
            </td>
          </tr>
        </tbody>
      </table>
      <SummarySectionStyled>
        <p>
          {t('clinicRegistration.payment_process.discount')}: €
          {promoCodeData
            ? summary - Number(promoCodeData.newAmount) / 100
            : '0'}
        </p>
        <h2>
          {t('clinicRegistration.payment_process.total')}: €
          {isOnlineServicePackage
            ? invoicingSubscriptionPrices
            : promoCodeData
            ? Number(promoCodeData?.newAmount) / 100
            : summary + invoicingSubscriptionPrices}
        </h2>
      </SummarySectionStyled>
    </PaymentOrderStyled>
  );
};

export default PaymentOrder;
