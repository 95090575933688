import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, InputWrapper, PromoCodeSectionStyled } from './styles';
import { Input, SecondaryButton } from 'common/components';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import {
  ClinicOwnerPackageDto,
  SubscriptionPackageResponse,
} from '@docbay/schemas';
import { useAppDispatch } from 'common/hooks/redux';
import { applyClinicOwnerPromocode } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { usePromoCodeError } from 'features/feature-clinic-registration/hooks/usePromoCodeError';

const PromoCodes: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [promoCode, setPromoCode] = useState('');
  const { subscriptionPackages, stripeDebit } =
    currentClinicOwnerRegistration();
  const { errorMessage, setPromoCodeError } = usePromoCodeError();

  const packages = ([] as SubscriptionPackageResponse[]).concat(
    ...Object.values(subscriptionPackages || {}),
  );

  const registrationData = JSON.parse(
    sessionStorage.getItem('clinicOwnerRegistration') || '{}',
  );
  const isYearly = registrationData.isYearlyPeriod;

  const invoicingPackage = registrationData.isInvoicing
    ? {
        product: registrationData.subscriptionInvoicingPackages.productId,
        price:
          registrationData.subscriptionInvoicingPackages.prices[
            isYearly ? 0 : 1
          ].id,
        quantity: 1,
        type: registrationData.subscriptionInvoicingPackages.type,
      }
    : {};

  const currentPriceId = registrationData.privateDoctor
    ? packages[0]?.prices[0].id
    : packages[0]?.prices[isYearly ? 0 : 1].id;

  const newPackages = [
    ...(packages?.length
      ? [
          {
            product: packages[0].productId,
            price: currentPriceId,
            quantity: packages.length,
            type: packages[0].type,
          },
        ]
      : []),
    ...(registrationData.isInvoicing ? [invoicingPackage] : []),
  ];

  const handleChangePromoCode = (value: string) => {
    setPromoCode(value);
    setPromoCodeError('');
  };

  const handleApplyPromoCode = async () => {
    const response = await dispatch(
      applyClinicOwnerPromocode({
        promocode: promoCode,
        packages: newPackages as ClinicOwnerPackageDto[],
        token: stripeDebit.token,
      }),
    );

    if (response.meta.requestStatus === 'rejected') {
      const errorResponse = response.payload as any;
      const errorCode = errorResponse?.data?.errorCode;
      setPromoCodeError(errorCode);
    }
  };

  return (
    <PromoCodeSectionStyled>
      <InputWrapper>
        <Input
          id={'code'}
          value={promoCode}
          onChange={(e) => handleChangePromoCode(e.target.value)}
          placeholder={t('promo_code') || ''}
          type={'text'}
          errorMessage={errorMessage}
        />
      </InputWrapper>

      <ButtonWrapper>
        <SecondaryButton
          onClick={handleApplyPromoCode}
          styleType="color"
          disabled={!promoCode.length}
        >
          {t('apply')}
        </SecondaryButton>
      </ButtonWrapper>
    </PromoCodeSectionStyled>
  );
};

export default PromoCodes;
