import {
  ApplyPromocodeResponseDto,
  ClinicOwnerCreationDto,
  ClinicOwnerResponseDto,
  InitializeSubscriptionResponseDto,
  SubscriptionPackageResponse,
} from '@docbay/schemas';
import { SetupIntent } from 'common/types/stripe';

enum SessionStorage {
  step = 'step',
  clinicOwnerRegistration = 'clinicOwnerRegistration',
  emailInput = 'emailInput',
  isShowBackModal = 'isShowBackModal',
  isReturnToStep4 = 'isReturnToStep4',
  isSentPackage = 'isSentPackage',
  isInvoicing = 'isInvoicing',
  isYearlyPeriod = 'isYearlyPeriod',
}

interface SubscriptionPackages {
  [quantity: string]: SubscriptionPackageResponse[];
}

interface ClinicOwnerRegistrationStorage {
  setupIntent: SetupIntent;
  stripeDebit: InitializeSubscriptionResponseDto;
  subscriptionPackages: SubscriptionPackages;
  subscriptionInvoicingPackages?: SubscriptionPackageResponse;
  contractualAgreement: {
    isAgree: boolean;
  };
  personalInfo: Partial<ClinicOwnerCreationDto> | any;
  timerEndDate: string;
  billingInfo: {
    companyName: string;
    address: {
      city: string;
      country: string;
      postal_code: string;
      address?: string;
    };
    iban: string;
  };
  setPassword: {
    code: number;
    password: string;
  };
  ownerId: Partial<ClinicOwnerResponseDto>;
  promoCode: ApplyPromocodeResponseDto;
  privateDoctor: boolean;
  isYearlyPeriod: boolean;
  isInvoicing: boolean;
}

// Select the Subscription Package
export const currentStep = parseInt(
  sessionStorage.getItem(SessionStorage.step) || '0',
);

export const setStepSessionStorage = (step: number) => {
  sessionStorage.setItem('step', String(step));
};

export const currentClinicOwnerRegistration =
  (): ClinicOwnerRegistrationStorage => {
    const packages = JSON.parse(
      sessionStorage.getItem(SessionStorage.clinicOwnerRegistration) || '{}',
    );

    return {
      setupIntent: packages?.setupIntent,
      stripeDebit: packages?.stripeDebit,
      subscriptionPackages: packages?.subscriptionPackages,
      subscriptionInvoicingPackages: packages.subscriptionInvoicingPackages,
      contractualAgreement: {
        isAgree: packages?.contractualAgreement?.isAgree,
      },
      personalInfo: {
        firstName: packages?.personalInfo?.firstName,
        lastName: packages?.personalInfo?.lastName,
        jobPosition: packages?.personalInfo?.jobPosition,
        email: packages?.personalInfo?.email,
        phone: packages?.personalInfo?.phone,
        supportedCountry: {
          id: packages?.personalInfo?.supportedCountry?.id || '',
          name: packages?.personalInfo?.supportedCountry?.name || '',
          code: packages?.personalInfo?.supportedCountry?.code || '',
        },
      },
      timerEndDate: packages?.timerEndDate?.timerEndDate,
      billingInfo: {
        companyName: packages?.billingInfo?.companyName,
        address: packages?.billingInfo?.address,
        iban: packages?.billingInfo?.iban,
      },
      setPassword: {
        code: packages?.setPassword?.code,
        password: packages?.setPassword?.password,
      },
      ownerId: packages?.ownerId,
      promoCode: packages.promoCode,
      privateDoctor: packages?.privateDoctor,
      isYearlyPeriod: packages.isYearlyPeriod,
      isInvoicing: packages.isInvoicing,
    };
  };

export const setClinicOwnerRegistrationSessionStorage = (
  name: keyof ClinicOwnerRegistrationStorage,
  value: ClinicOwnerRegistrationStorage[keyof ClinicOwnerRegistrationStorage],
) => {
  sessionStorage.setItem(
    SessionStorage.clinicOwnerRegistration,
    JSON.stringify({
      ...currentClinicOwnerRegistration(),
      [name]: value,
    }),
  );
};

// Set Password
export const focusOnEmailInput = () => {
  const isFocused = sessionStorage.getItem(SessionStorage.emailInput)
    ? JSON.parse(sessionStorage.getItem(SessionStorage.emailInput) || '')
    : false;
  return isFocused;
};

export const setFocusOnEmailSessionStorage = (value: boolean) => {
  sessionStorage.setItem(SessionStorage.emailInput, JSON.stringify(value));
};

export const setIsBackModalShowSessionStorage = () => {
  sessionStorage.setItem(SessionStorage.isShowBackModal, 'Y');
};

export const getIsBackModalShowSessionStorage = () => {
  return sessionStorage.getItem(SessionStorage.isShowBackModal) || 'N';
};

export const clearClinicOwnerSessionStorage = () => {
  sessionStorage.removeItem(SessionStorage.step);
  sessionStorage.removeItem(SessionStorage.clinicOwnerRegistration);
  sessionStorage.removeItem(SessionStorage.emailInput);
  sessionStorage.removeItem(SessionStorage.isShowBackModal);
  sessionStorage.removeItem(SessionStorage.isReturnToStep4);
  sessionStorage.removeItem(SessionStorage.isSentPackage);
  sessionStorage.removeItem(SessionStorage.isYearlyPeriod);
  sessionStorage.removeItem(SessionStorage.isInvoicing);
};

export const getRegistrationToken = () => {
  const data = JSON.parse(
    sessionStorage.getItem(SessionStorage.clinicOwnerRegistration)!,
  );

  return data?.stripeDebit?.token || '';
};

export const isSentPackage = () => {
  return sessionStorage.getItem(SessionStorage.isSentPackage) || false;
};

export const setIsSentPackage = () => {
  return sessionStorage.setItem(SessionStorage.isSentPackage, 'true');
};
