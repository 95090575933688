import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderStyled } from './styles';

const Header: FC = () => {
  const { t } = useTranslation();

  return <HeaderStyled>{t('clinic_owner.billing.billing')}</HeaderStyled>;
};

export default Header;
