import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ClinicOwnerSubscriptionType } from '@docbay/schemas';

import { InfoOutlinedIcon } from 'applicaiton/assets';
import { getSubscriptionsAction } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import {
  getCurrentSubscription,
  getPaymentMethods,
  updateCurrentSubscription,
} from 'applicaiton/store/reducers/ClinicOwnerSubscription/ActionCreators';
import {
  setBillingInfo,
  setSelectedPackagesData,
} from 'applicaiton/store/reducers/ClinicOwnerSubscription/ClinicOwnerSubscriptionSlice';
import { getIsUserPrivateDoctor } from 'applicaiton/sessionStorage/auth';
import { getUserProfile } from 'applicaiton/store/reducers/Users/ActionCreators';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ConfirmModal, Loader, SuccessModal } from 'common/components';

import { PackFeatures } from 'features/feature-clinic-registration/constants';
import { getCurrentPeriodPrice } from 'features/feature-manage-plan/helpers/getCurrentPeriodPrice';

import Package from '../Package';

import { PackageDto, PlansListProps } from './models';
import { Wrapper, TopSection, ListStyled, ConfirmModalStyled } from './styles';

const PlansList: FC<PlansListProps> = ({ setStep }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isUserPrivateDoctor = getIsUserPrivateDoctor();
  const { isLoading, subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const {
    isLoading: isCurrentSubscriptionLoading,
    subscriptionPackage,
    professionalsCount,
    featurePackages,
    paymentMethods,
  } = useAppSelector((state) => state.clinicOwnerSubscription);
  const { isLoading: isUserProfileLoading } = useAppSelector(
    (state) => state.users,
  );

  const [currentPackage, setCurrentPackage] = useState<PackageDto | null>(null);
  const [packages, setPackages] = useState<PackageDto[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showQuantityError, setShowQuantityError] = useState(false);

  const loading =
    isLoading || isCurrentSubscriptionLoading || isUserProfileLoading;

  const isCurrentPlan =
    subscriptionPackage?.subscriptionType === currentPackage?.type;

  const filteredSubscriptions = useMemo(() => {
    return subscriptions?.filter(
      (item) => String(item.type) !== PackFeatures.invoicingFeature,
    );
  }, [subscriptions]);

  const confirmModalTitle = useMemo(() => {
    return isCurrentPlan
      ? t('change_plan.confirm_upgrade_plant_title')
      : t('change_plan.confirm_switch_plant_title');
  }, [isCurrentPlan, t]);

  const conformModalText = useMemo(() => {
    return isCurrentPlan
      ? t('change_plan.confirm_upgrade_plant_text')
      : t('change_plan.confirm_switch_plant_text');
  }, [isCurrentPlan, t]);

  const endDate = dayjs(
    subscriptionPackage?.purchasedSubscription?.currentPeriodEnd,
  )
    .locale(i18n.language)
    .format('MMM DD, YYYY');

  useEffect(() => {
    dispatch(getSubscriptionsAction());
    dispatch(getCurrentSubscription());
    dispatch(getPaymentMethods());
    dispatch(
      setSelectedPackagesData({
        selectedPackages: [],
        selectedInvoicingPackage: null,
        isYearlySubscription: false,
      }),
    );
    dispatch(setBillingInfo(null));
  }, []);

  useEffect(() => {
    if (subscriptionPackage && filteredSubscriptions.length) {
      const isYearlySubscription =
        dayjs(subscriptionPackage.purchasedSubscription?.currentPeriodEnd).diff(
          subscriptionPackage.purchasedSubscription?.currentPeriodStart,
          'month',
        ) > 1;
      const currentPlan = filteredSubscriptions.find(
        (item) => item.type === subscriptionPackage?.subscriptionType,
      );
      const price = getCurrentPeriodPrice(
        currentPlan?.prices || [],
        isYearlySubscription,
      );
      const invoicingFeature = featurePackages.length
        ? subscriptions?.filter((item) => {
            const findFeature = featurePackages.find(
              (feature) => feature.subscriptionType === item.type,
            );
            if (findFeature) {
              return item;
            }
          }) || []
        : [];
      const currentFeaturePackages = invoicingFeature.map((item) => {
        return {
          product: item.productId,
          price:
            getCurrentPeriodPrice(item.prices, isYearlySubscription)?.id || '',
          quantity: 1,
          type: item.type,
        };
      });
      const packagesList = filteredSubscriptions.map((item) => {
        if (item.type !== currentPlan?.type) {
          return {
            name: item.name,
            product: item?.productId || '',
            price:
              item.prices.find((price) => price.recurringInterval === 'month')
                ?.id || '',
            prices: item.prices,
            privateDoctor: item.privateDoctor,
            quantity: 0,
            type: item?.type || '',
            isYearlyPeriod: false,
            featurePackages: [],
          };
        } else {
          return {
            name: item.name,
            product: item.productId,
            price: price?.id || '',

            prices: item.prices,
            privateDoctor: item.privateDoctor,
            quantity:
              subscriptionPackage.purchasedSubscription
                ?.numberOfProfessionals || 0,
            type: item.type,
            isYearlyPeriod: isYearlySubscription,
            featurePackages: currentFeaturePackages,
          };
        }
      });
      setPackages(packagesList);
      setCurrentPackage(
        currentPlan
          ? {
              name: currentPlan.name,
              product: currentPlan.productId,
              price: price?.id || '',
              prices: currentPlan.prices,
              privateDoctor: currentPlan.privateDoctor,
              quantity:
                subscriptionPackage.purchasedSubscription
                  ?.numberOfProfessionals || 0,
              type: currentPlan.type,
              isYearlyPeriod: isYearlySubscription,
              featurePackages: currentFeaturePackages,
            }
          : null,
      );
    } else {
      const packagesList = filteredSubscriptions.map((item) => {
        return {
          name: item.name,
          product: item?.productId || '',
          price:
            item.prices.find((price) => price.recurringInterval === 'month')
              ?.id || '',
          prices: item.prices,
          privateDoctor: item.privateDoctor,
          quantity: 0,
          type: item?.type || '',
          isYearlyPeriod: false,
          featurePackages: [],
        };
      });
      setPackages(packagesList);
    }
  }, [subscriptionPackage, filteredSubscriptions, featurePackages]);

  const handleChangeQuantity = (value: number, subscription: PackageDto) => {
    const minValue = professionalsCount || 1;
    const hasQuantityError =
      minValue > value &&
      subscriptionPackage?.subscriptionType === subscription.type;
    if (hasQuantityError) {
      setShowQuantityError(true);
      return setTimeout(() => {
        !showQuantityError && setShowQuantityError(false);
      }, 3000);
    }
    setShowQuantityError(false);
    setPackages((prev) =>
      prev.map((item) => {
        if (item.type === subscription.type) {
          return { ...subscription, quantity: value };
        }
        return item;
      }),
    );
    setCurrentPackage({ ...subscription, quantity: value });
  };

  const handleChangePlan = (subscription: PackageDto) => {
    const isCurrentPlan = subscription.type === currentPackage?.type;

    if (!isCurrentPlan) {
      setCurrentPackage(subscription);
    }
  };

  const handlePeriodChange = (value: boolean, subscription: PackageDto) => {
    const invoicingFeature = subscription.featurePackages.length
      ? subscriptions?.filter(
          (item) => String(item.type) === PackFeatures.invoicingFeature,
        )
      : [];
    const currentFeaturePackages = invoicingFeature.map((item) => ({
      product: item.productId,
      price: getCurrentPeriodPrice(item.prices, value)?.id || '',
      quantity: 1,
      type: item.type,
    }));

    const currentProduct = subscriptions.find(
      (item) => item.type === subscription.type,
    );
    const price = getCurrentPeriodPrice(currentProduct?.prices || [], value);
    setPackages((prev) =>
      prev.map((item) => {
        if (item.type === subscription.type) {
          return {
            ...item,
            price: price?.id || '',
            isYearlyPeriod: value,
            featurePackages: currentFeaturePackages,
          };
        }
        return item;
      }),
    );
    setCurrentPackage((prev) => ({
      ...prev!,
      price: price?.id || '',
      isYearlyPeriod: value,
      featurePackages: currentFeaturePackages,
    }));
  };

  const handleChangeInvoicing = (value: boolean, subscription: PackageDto) => {
    const invoicingFeature =
      subscriptions?.filter(
        (item) => String(item.type) === PackFeatures.invoicingFeature,
      ) || [];

    const currentFeaturePackages = invoicingFeature.map((item) => ({
      product: item.productId,
      price:
        getCurrentPeriodPrice(item.prices, !!currentPackage?.isYearlyPeriod)
          ?.id || '',
      quantity: 1,
      type: item.type,
    }));

    setPackages((prev) =>
      prev.map((item) => {
        if (item.type === subscription.type) {
          return {
            ...item,
            featurePackages: value ? currentFeaturePackages : ([] as any),
          };
        }
        return item;
      }),
    );
    setCurrentPackage((prev) => ({
      ...prev!,
      featurePackages: value ? currentFeaturePackages : ([] as any),
    }));
  };

  const handleShowConfirmModal = () => {
    const hasDefaultPaymentMethod = paymentMethods.some(
      (item) => item.isDefault,
    );
    const shouldAddPaymentMethod =
      !subscriptionPackage || !hasDefaultPaymentMethod;

    if (shouldAddPaymentMethod) {
      const currentSubscription = filteredSubscriptions.find(
        (item) => item.type === currentPackage?.type,
      );
      if (currentSubscription) {
        const selectedSubscriptions = Array.from(
          Array(currentPackage?.quantity || 1).keys(),
        ).map((_) => {
          return currentSubscription;
        });
        const invoicingSubscription = currentPackage?.featurePackages.length
          ? subscriptions.find(
              (item) => String(item.type) === PackFeatures.invoicingFeature,
            ) || null
          : null;

        dispatch(
          setSelectedPackagesData({
            selectedPackages: selectedSubscriptions,
            selectedInvoicingPackage: invoicingSubscription,
            isYearlySubscription: !!currentPackage?.isYearlyPeriod,
          }),
        );
      }
      const minValue = professionalsCount || 1;
      const hasQuantityError = minValue > (currentPackage?.quantity || 0);

      if (hasQuantityError && !isUserPrivateDoctor) {
        setShowQuantityError(true);
        return setTimeout(() => {
          !showQuantityError && setShowQuantityError(false);
        }, 3000);
      }

      return setStep(2);
    }
    setShowConfirmModal(true);
  };

  const onCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const onCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const onSubmit = async () => {
    if (currentPackage) {
      const { featurePackages, price, product, quantity, type } =
        currentPackage;
      const response = await dispatch(
        updateCurrentSubscription({
          packages: [
            {
              product,
              price,
              quantity,
              type: type as ClinicOwnerSubscriptionType,
            },
            ...featurePackages,
          ],
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        await dispatch(getUserProfile());
        onCloseConfirmModal();
        setShowSuccessModal(true);
        dispatch(getSubscriptionsAction());
        dispatch(getCurrentSubscription());
      }
    }
  };

  return (
    <Wrapper>
      {loading && <Loader />}
      <TopSection>
        <h2>{t('change_plan.manage_plan')}</h2>
        {subscriptionPackage && (
          <div>
            <InfoOutlinedIcon />
            <p>
              {t('change_plan.your')} <b>{subscriptionPackage?.productName}</b>{' '}
              {t('change_plan.plan_ends_on')}
            </p>
            <b>{endDate}</b>
          </div>
        )}
      </TopSection>
      <ListStyled>
        {packages.map((item) => (
          <Package
            key={item.product}
            id={item.product}
            quantity={item.quantity}
            title={item.name}
            isActive={item.type === currentPackage?.type}
            isPrivateDoctor={!!item?.privateDoctor}
            packageType={String(item.type)}
            prices={item.prices}
            isYearly={item.isYearlyPeriod}
            isInvoicing={!!item.featurePackages.length}
            onClick={() => handleChangePlan(item)}
            handleSetQuantity={(value) => {
              handleChangeQuantity(value, item);
            }}
            handlePeriodChange={(value) => {
              handlePeriodChange(value, item);
            }}
            handleChangeInvoicing={(value) => {
              handleChangeInvoicing(value, item);
            }}
            onSubmit={handleShowConfirmModal}
            showQuantityError={showQuantityError}
          />
        ))}
      </ListStyled>
      <ConfirmModal
        opened={showConfirmModal}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmit}
        isLoading={loading}
      >
        <ConfirmModalStyled>
          <h1>
            {confirmModalTitle} <span>{currentPackage?.name}</span>?
          </h1>
          <p>{conformModalText}</p>
        </ConfirmModalStyled>
      </ConfirmModal>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={onCloseSuccessModal}
        closeButtonText={t('ok') || ''}
        message={t('change_plan.success_modal_update')}
      />
    </Wrapper>
  );
};

export default PlansList;
