import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentRequestResponseDto } from '@docbay/schemas';

import {
  deleteAppointmentRequest,
  fetchAppointmentRequestList,
} from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserRole } from 'applicaiton/sessionStorage/auth';

import {
  DeleteConfirmModal,
  Loader,
  Pagination,
  SuccessModal,
} from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import NoDataElement from '../NoDataElement';
import TableRow from '../TableRow';

import { TableStyled, Wrapper } from './styles';

const WaitingAppointmentsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { requestList, isLoading, page, total, totalPages } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const [requestToDelete, setRequestToDelete] =
    useState<AppointmentRequestResponseDto | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);

  const userRole = getUserRole();
  const isProfessional = userRole === UserRoles.professional;

  const getRequestList = async (page: number) => {
    await dispatch(
      fetchAppointmentRequestList({
        page,
        limit: 10,
      }),
    );
  };

  useEffect(() => {
    getRequestList(1);
  }, []);

  const handleChangePage = async (currentPage: number) => {
    if (currentPage !== page) {
      await getRequestList(currentPage);
    }
  };

  const onDelete = async () => {
    const response = await dispatch(
      deleteAppointmentRequest(requestToDelete?.id!),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      const currentPage = requestList.length > 1 && page > 1 ? page : page - 1;
      getRequestList(currentPage);
      setShowDeleteModal(false);
      setShowSuccessDeleteModal(true);
    }
  };

  const handleOpenDeleteModal = (request: AppointmentRequestResponseDto) => {
    setRequestToDelete(request);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setRequestToDelete(null);
  };

  const handleCloseSuccessDeleteModal = () => {
    setShowSuccessDeleteModal(false);
    setRequestToDelete(null);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <TableStyled>
        <thead>
          <tr>
            <th className="date-column">{t('waiting_list.table.date')}</th>
            {!isProfessional && (
              <th className="professional-column">
                {t('waiting_list.table.professional')}
              </th>
            )}
            <th className="patient-column">
              {t('waiting_list.table.patient')}
            </th>
            <th className="phone-column">{t('my_patients.phone')}</th>
            <th>{t('waiting_list.table.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {requestList.map((item) => (
            <TableRow
              key={item.id}
              item={item}
              onDelete={handleOpenDeleteModal}
              isProfessional={isProfessional}
            />
          ))}
          {!total && !isLoading && <NoDataElement />}
        </tbody>
      </TableStyled>
      {!!total && (
        <Pagination
          pages={totalPages}
          setCurrentPage={handleChangePage}
          currentPage={page}
          itemCount={total}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          opened={showDeleteModal}
          onClose={handleCloseDeleteModal}
          onSubmit={onDelete}
          title={t('appointment_request_list.confirm_delete_modal.title')}
          name={`${requestToDelete?.patient.firstName} ${requestToDelete?.patient.lastName}`}
          subTitle={t(
            'appointment_request_list.confirm_delete_modal.sub_title',
          )}
          options={[
            t('appointment_request_list.confirm_delete_modal.option_1'),
            t('appointment_request_list.confirm_delete_modal.option_2'),
          ]}
          isLoading={isLoading}
        />
      )}
      {showSuccessDeleteModal && (
        <SuccessModal
          isOpen={showSuccessDeleteModal}
          onClose={handleCloseSuccessDeleteModal}
          message={`${t('success_delete_modal.text_1')} ${
            requestToDelete?.patient.firstName
          } ${requestToDelete?.patient.lastName} ${t(
            'success_delete_modal.text_2',
          )}`}
          closeButtonText={t('ok') || ''}
        />
      )}
    </Wrapper>
  );
};

export default WaitingAppointmentsTable;
