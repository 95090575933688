import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
`;

export const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  color: ${commonColors.grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.grey600};
    }
  }
`;

export const HeaderTitleStyled = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.color.grey900};
    margin-bottom: 8px;
  }

  > div {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: ${({ theme }) => theme.color.grey700};
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  button {
    width: 110px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
    :disabled {
      svg {
        path {
          fill: ${({ theme }) => theme.color.blue200};
        }
      }
    }
  }
`;

export const SepaStyled = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;
