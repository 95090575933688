import styled from 'styled-components';

import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 32px 32px 32px 20px;
  background-color: ${commonColors.background};
`;
