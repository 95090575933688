import styled from 'styled-components';

interface StyledProps {
  isSingleView: boolean;
  isLoading: boolean;
}

export const SchedulerWrapper = styled.section<StyledProps>`
  display: flex;
  flex: 1;
  overflow-x: auto;
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};

  .MainLayout-container {
    height: 100%;
    > div {
      min-height: 100%;
      display: flex;
      flex-direction: column;

      .MainLayout-flexRow {
        height: 100%;
        flex: 1;
        .MuiTable-root.Layout-timeScaleContainer {
          height: 100%;
          width: 56px;
          .MuiTableBody-root {
            display: flex;
            height: 100%;
            > tr {
              display: flex;
              height: 100%;
              > td {
                display: grid;
                grid-template-rows: auto;
                > div {
                  flex: 1;
                  box-sizing: border-box;
                  &:last-child {
                    display: none;
                  }
                }
              }
            }
            @-moz-document url-prefix() {
              height: ${({ isSingleView }) =>
                isSingleView ? 'calc(100vh - 172px)' : 'calc(100vh - 165px)'};
            }
          }
        }
      }
      .MainLayout-stickyElement.MainLayout-header.MainLayout-flexRow {
        height: unset;
        flex: unset;
      }
    }
    div {
      border-color: ${({ theme }) => theme.color.grey300};
    }

    td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Cell-cell.Cell-horizontalCell {
      width: 330px;
      max-width: 100%;
    }

    .Appointment-appointment {
      border: none;
    }

    table {
      &.MuiTable-root.Table-table {
        height: 100%;
      }
      tbody {
        border-color: ${({ theme }) => theme.color.grey300};
        td {
          &.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Layout-cell {
            vertical-align: text-top;
            bottom: 0.5px;
            position: relative;
          }
          .Cell-dayView {
            display: ${({ isSingleView }) => (isSingleView ? 'none' : 'flex')};
            flex-direction: column-reverse;
            align-items: center;
            .Cell-dayOfWeek,
            .Cell-dayOfMonth {
              color: ${({ theme }) => theme.color.grey800};
              font-size: 16px;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
            .Cell-dayOfWeek {
              &.Cell-highlightedText {
                font-weight: 700;
              }
            }

            .Cell-dayOfMonth {
              font-size: 20px;
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 36px;

              &.Cell-highlightedText {
                border-radius: 50%;
                background: ${({ theme }) => theme.color.blue300};
              }
            }
          }
          .Label-label {
            height: 7px;
            width: 80px;
            display: flex;
            justify-content: center;
            padding: 0;
            border-top: 1px solid ${({ theme }) => theme.color.grey300};
            position: relative;

            > span {
              color: ${({ theme }) => theme.color.grey800};
              font-size: 16px;
              font-weight: 400;
              line-height: normal;
            }
            &:first-child {
              border: 0;
            }

            &.Label-emptyLabel {
              &:last-child {
                display: none;
              }
            }
          }
          border-color: ${({ theme }) => theme.color.grey300};
        }
      }
    }
  }

  table.MuiTable-root.TicksLayout-table.Layout-ticks {
    display: none;
    border: none;
    td {
      border: none;
    }
  }

  .MainLayout-stickyElement.MainLayout-header.MainLayout-flexRow {
    min-height: 89px;
    height: fit-content;

    .MainLayout-ordinaryLeftPanelBorder {
      width: 57px;
      min-width: 57px;
    }

    table {
      height: 100%;

      > tbody {
        td {
          .Cell-text {
            width: 100%;
            font-size: 0;
          }
        }
      }
    }
  }
`;
