import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { ReactComponent as TeleconsultationIcon } from 'applicaiton/assets/teleconsultation.svg';
import { ReactComponent as TeleconsultationCameraIcon } from 'applicaiton/assets/chat-camera.svg';
import { BellIcon } from 'applicaiton/assets';
import { ChatsMode } from 'applicaiton/constants/chats';

import { formatDateTimeInDefaultTimezone } from 'common/helpers/dateTimeHelper';
import { useAppSelector } from 'common/hooks/redux';
import { SecondaryButton } from 'common/components';
import { useDynamicTime } from 'common/hooks/useDynamicTime';

import { useConversation } from 'features/feature-chats/hooks/useConversation';
import { useTeleconsultationRequest } from 'features/feature-chats/hooks/useTeleconsultationRequest';

import MessageDocument from './MessageDocument';
import { MessageListItemsProps } from './module';
import {
  MessageStyled,
  TeleconsultationIconStyled,
  TeleconsultationMessageStyled,
  TeleconsutationButtonWrapper,
  TopicClosedMessage,
  MessageTitle,
  DividerStyled,
} from './styles';

export const MessageListItem = ({
  message,
  onSelectMessage,
  onDelete,
  onDownload,
}: MessageListItemsProps) => {
  const { t } = useTranslation();
  const { selectedChat } = useConversation();
  const { mode } = useAppSelector((state) => state.chats);
  const { dynamicTime, updateAndStopDynamicTime, startDynamicTime } =
    useDynamicTime(10000);

  const { acceptTeleconsultationRequest, joinTeleconsultation } =
    useTeleconsultationRequest();
  const isTeleconsultationRequest = !!message.teleconsultationRequest;
  const isCloseTopicMessage = message.topicClosed;
  const isJoinTeleconsultation =
    !!message.teleconsultation && !!message.teleconsultationObject;
  const isSimpleMessage =
    !isTeleconsultationRequest &&
    !isCloseTopicMessage &&
    !isJoinTeleconsultation;

  const messageTime = message.date
    ? formatDateTimeInDefaultTimezone(message.date, 'HH:mm')
    : '';

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (mode === ChatsMode.ArchivedChats) return;
    onSelectMessage(message, event.currentTarget);
  };

  const onAcceptTeleconsultationRequest = async () => {
    if (selectedChat?.twilioConversationSID) {
      acceptTeleconsultationRequest(
        selectedChat.twilioConversationSID,
        message.id,
      );
    }
  };

  useEffect(() => {
    if (isJoinTeleconsultation && !checkJoinTeleconsultationExpired()) {
      startDynamicTime();
    }
  }, [message]);

  const checkJoinTeleconsultationExpired = () => {
    const teleconsultationTtl = message.teleconsultationObject?.ttl;
    if (!teleconsultationTtl) {
      return true;
    }
    const expiresAt = dayjs(message.date).add(teleconsultationTtl, 'seconds');
    return dayjs().isAfter(expiresAt);
  };

  const onJoinTeleconsultation = () => {
    if (checkJoinTeleconsultationExpired()) {
      updateAndStopDynamicTime();
      return;
    }
    joinTeleconsultation(message.teleconsultationObject?.room);
  };

  const joinTeleconsultationDisabled = useMemo(() => {
    const result = checkJoinTeleconsultationExpired();
    if (result) {
      updateAndStopDynamicTime();
    }
    return result;
  }, [message, dynamicTime]);

  return (
    <>
      {isCloseTopicMessage && (
        <TopicClosedMessage>
          <BellIcon />
          {t('chats.closed_topic')}
        </TopicClosedMessage>
      )}
      {isTeleconsultationRequest && (
        <TeleconsultationMessageStyled
          className={message.isOwnMessage ? 'own-message' : ''}
        >
          <div>
            <TeleconsultationIconStyled>
              <TeleconsultationIcon />
            </TeleconsultationIconStyled>
            <p className={'message-text'}>
              {t('chats.active_chat.instant_teleconsulation_request')}
            </p>
          </div>
          <p className={'message-time'}>{messageTime}</p>
          <TeleconsutationButtonWrapper>
            <SecondaryButton
              type="button"
              styleType={'color'}
              onClick={onAcceptTeleconsultationRequest}
            >
              <TeleconsultationCameraIcon />
              {t('chats.active_chat.start_teleconsulation')}
            </SecondaryButton>
          </TeleconsutationButtonWrapper>
        </TeleconsultationMessageStyled>
      )}
      {isJoinTeleconsultation && (
        <TeleconsultationMessageStyled
          className={message.isOwnMessage ? 'own-message' : ''}
        >
          <div>
            <TeleconsultationIconStyled>
              <TeleconsultationIcon />
            </TeleconsultationIconStyled>
            <p className={'message-text'}>
              {t('chats.active_chat.join_teleconsultation')}
            </p>
          </div>
          <p className={'message-time'}>{messageTime}</p>
          <TeleconsutationButtonWrapper>
            <SecondaryButton
              disabled={joinTeleconsultationDisabled}
              type="button"
              styleType={'color'}
              onClick={onJoinTeleconsultation}
            >
              <TeleconsultationCameraIcon />
              {t('chats.active_chat.join_teleconsultation')}
            </SecondaryButton>
          </TeleconsutationButtonWrapper>
        </TeleconsultationMessageStyled>
      )}
      {isSimpleMessage && (
        <>
          {message.document ? (
            <MessageDocument
              message={message}
              mode={mode}
              handleOpenMenu={handleOpenMenu}
              messageTime={messageTime}
              onDelete={onDelete}
              onDownload={onDownload}
            />
          ) : (
            <MessageStyled
              disabled={mode === ChatsMode.ArchivedChats}
              className={message.isOwnMessage ? 'own-message' : ''}
              onClick={handleOpenMenu}
              emergency={message.emergency}
            >
              <textarea
                className={'message-text'}
                value={message.text}
                readOnly
              />
              <p className={'message-time'}>{messageTime}</p>
            </MessageStyled>
          )}
        </>
      )}
      {message.messageTitle && (
        <MessageTitle>
          <DividerStyled />
          <p>{message.messageTitle}</p>
          <DividerStyled />
        </MessageTitle>
      )}
    </>
  );
};
