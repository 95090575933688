import React, { FC } from 'react';
import { AdditionalPrice, PeriodName, PriceSwitcher, Switcher } from './styles';
import Switch from 'common/components/Switch';
import { ReactComponent as LightningIcon } from 'applicaiton/assets/lightning.svg';
import { setClinicOwnerRegistrationSessionStorage } from 'applicaiton/sessionStorage/clinicRegistration';
import { useTranslation } from 'react-i18next';

interface Props {
  isYearly: boolean;
  isEnabled: boolean;
  isActive: boolean;
  onChange: (value: boolean) => void;
}
const PayPeriodSwitcher: FC<Props> = ({
  isYearly,
  isEnabled,
  isActive,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <PriceSwitcher>
      <Switcher>
        <PeriodName isActive={!isYearly}>{t('monthly')}</PeriodName>
        <Switch
          key={'isYearlyPeriod'}
          checked={isEnabled}
          onChange={(value) => {
            onChange(value);
            setClinicOwnerRegistrationSessionStorage('isYearlyPeriod', value);
          }}
        />
        <PeriodName isActive={isYearly}>{t('annually')}</PeriodName>
      </Switcher>
      <AdditionalPrice>
        <LightningIcon /> {t('annual_payment')}: -20%
      </AdditionalPrice>
    </PriceSwitcher>
  );
};

export default PayPeriodSwitcher;
