import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  applyClinicOwnerPromocode,
  clinicOwnerConfirmSignUp,
  clinicOwnerPayments,
  clinicOwnerSignUp,
  getSubscriptionsAction,
  resendConfirmationCode,
} from './ActionCreators';
import { InitialState } from './models';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';

const { subscriptionPackages } = currentClinicOwnerRegistration();

const shouldPay = !subscriptionPackages
  ? true
  : Object.values(subscriptionPackages)
      .flat(1)
      .some((item) => Number(item.prices[0].unitAmountDecimal) > 0);

const initialState: InitialState = {
  isLoading: false,
  subscriptions: [],
  createdOwner: null,
  error: '',
  isClinicOwnerConfirmSignUp: false,
  isSuccessClinicOwnerPayments: false,
  isSuccessClinicOwnerSignUp: false,
  isAlreadyPaid: false,
  isSubscriptionsFree: !shouldPay,
  promoCodeData: null,
};

const clinicRegistrationSlice = createSlice({
  name: 'clinicOwner',
  initialState,
  reducers: {
    setClinicRegistrationError: (state, actions) => {
      state.error = actions.payload!;
    },
    setIsClinicOwnerConfirmSignUp: (state, actions) => {
      state.isClinicOwnerConfirmSignUp = actions.payload!;
    },
    setIsSuccessClinicOwnerPayments: (state, actions) => {
      state.isSuccessClinicOwnerPayments = actions.payload!;
    },
    setIsSuccessClinicOwnerSignUp: (state, actions) => {
      state.isSuccessClinicOwnerSignUp = actions.payload!;
    },
    setAlreadyPaid: (state, actions) => {
      state.isAlreadyPaid = actions.payload;
    },
    clearClinicOwnerSuccessSignUp: (state) => {
      state.isClinicOwnerConfirmSignUp = false;
      state.isSuccessClinicOwnerPayments = false;
      state.isSuccessClinicOwnerSignUp = false;
      state.isAlreadyPaid = false;
    },
    setIsSubscriptionsFree: (state, action: PayloadAction<boolean>) => {
      state.isSubscriptionsFree = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptionsAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSubscriptionsAction.fulfilled, (state, action) => {
      state.subscriptions = action.payload;
      state.isLoading = false;
    });
    builder.addCase(clinicOwnerSignUp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerSignUp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isSuccessClinicOwnerSignUp = false;
    });
    builder.addCase(clinicOwnerSignUp.fulfilled, (state, action) => {
      state.createdOwner = action.payload;
      state.isLoading = false;
      state.isSuccessClinicOwnerSignUp = true;
    });

    builder.addCase(clinicOwnerConfirmSignUp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerConfirmSignUp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isClinicOwnerConfirmSignUp = false;
    });
    builder.addCase(clinicOwnerConfirmSignUp.fulfilled, (state) => {
      state.isClinicOwnerConfirmSignUp = true;
      state.isLoading = false;
    });

    builder.addCase(clinicOwnerPayments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerPayments.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccessClinicOwnerPayments = false;
      state.error = action.payload as string;
    });
    builder.addCase(clinicOwnerPayments.fulfilled, (state, action) => {
      const isAlreadyPaid = action.payload.alreadyPaid;
      if (isAlreadyPaid) {
        state.isAlreadyPaid = true;
      }
      state.isSuccessClinicOwnerPayments = true;
      state.isLoading = false;
    });
    //resend code
    builder.addCase(resendConfirmationCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resendConfirmationCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(resendConfirmationCode.fulfilled, (state) => {
      state.isLoading = false;
    });
    //apply promocode
    builder.addCase(applyClinicOwnerPromocode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(applyClinicOwnerPromocode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(applyClinicOwnerPromocode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.promoCodeData = action.payload;
    });
  },
});

export const {
  setClinicRegistrationError,
  setIsClinicOwnerConfirmSignUp,
  setIsSuccessClinicOwnerPayments,
  setIsSuccessClinicOwnerSignUp,
  clearClinicOwnerSuccessSignUp,
  setAlreadyPaid,
  setIsSubscriptionsFree,
} = clinicRegistrationSlice.actions;

export default clinicRegistrationSlice.reducer;
