import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.color.grey500};
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19.6px;
  color: ${({ theme }) => theme.color.grey900};
`;

export const FeatureItem = styled.div<Props>`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: flex-start;
  gap: 8px;

  p {
    color: ${({ theme }) => theme.color.grey800};
    font-weight: 400;
  }

  svg {
    min-width: ${({ isActive }) => (isActive ? '18px' : '24px')};
    min-height: ${({ isActive }) => (isActive ? '18px' : '24px')};

    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue700 : theme.color.notActive};
    }
  }
`;
