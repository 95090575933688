import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LightningIcon } from 'applicaiton/assets/lightning.svg';
import { setClinicOwnerRegistrationSessionStorage } from 'applicaiton/sessionStorage/clinicRegistration';

import Switch from 'common/components/Switch';

import { PayPeriodSwitcherProps } from './models';
import { AdditionalPrice, PeriodName, PriceSwitcher, Switcher } from './styles';

const PayPeriodSwitcher: FC<PayPeriodSwitcherProps> = ({
  isYearly,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <PriceSwitcher>
      <Switcher>
        <PeriodName isActive={!isYearly}>{t('monthly')}</PeriodName>
        <Switch
          key={'isYearlyPeriod'}
          checked={isYearly}
          onChange={(value) => {
            onChange(value);
            setClinicOwnerRegistrationSessionStorage('isYearlyPeriod', value);
          }}
          disabled={disabled}
        />
        <PeriodName isActive={isYearly}>{t('annually')}</PeriodName>
      </Switcher>
      <AdditionalPrice>
        <LightningIcon /> {t('annual_payment')}: -20%
      </AdditionalPrice>
    </PriceSwitcher>
  );
};

export default PayPeriodSwitcher;
