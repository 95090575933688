import styled from 'styled-components';

export const Wrapper = styled.article`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    > section {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
`;
