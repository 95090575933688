import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPackageResponse } from '@docbay/schemas';
import { ReactComponent as QuantityIcon } from 'applicaiton/assets/quantity.svg';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import { PaymentOrderStyled, SummarySectionStyled } from './styles';
import { PaymentOrderProps } from './modules';
import PromoCodes from '../PromoCodes';
import { getCurrentPeriodPrice } from 'features/feature-manage-plan/helpers/getCurrentPeriodPrice';

const PaymentOrder: FC<PaymentOrderProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const { subscriptionPackages } = currentClinicOwnerRegistration();
  const { promoCode } = currentClinicOwnerRegistration();

  const registrationData = JSON.parse(
    sessionStorage.getItem('clinicOwnerRegistration') || '{}',
  );

  const invoicingSubscriptionPrices = useMemo(() => {
    const invoicingSubscription =
      registrationData.subscriptionInvoicingPackages;

    return invoicingSubscription?.prices?.length
      ? Number(
          invoicingSubscription?.prices[registrationData.isYearlyPeriod ? 0 : 1]
            .unitAmountDecimal,
        ) / 100
      : 0;
  }, [
    registrationData.subscriptionInvoicingPackages,
    registrationData.isYearlyPeriod,
  ]);

  const packages: SubscriptionPackageResponse[] = useMemo(() => {
    let uniqList = [];
    for (const key in subscriptionPackages) {
      uniqList.push(subscriptionPackages[key][0]);
    }
    return uniqList;
  }, [subscriptionPackages]);

  const firstDoctorPrice = useMemo(() => {
    if (registrationData.privateDoctor) return 0;

    const currentPrice = getCurrentPeriodPrice(
      packages[0]?.prices || [],
      registrationData.isYearlyPeriod,
    );

    const price = currentPrice?.tiers?.length
      ? Number(currentPrice.tiers[0].unitAmountDecimal)
      : Number(currentPrice?.unitAmountDecimal || 0);

    return price / 100;
  }, [packages, registrationData]);

  const nextDoctorPrice = useMemo(() => {
    if (registrationData.privateDoctor) return 0;

    const currentPrice = getCurrentPeriodPrice(
      packages[0]?.prices || [],
      registrationData.isYearlyPeriod,
    );

    const price = currentPrice?.tiers?.length
      ? Number(currentPrice.tiers[1].unitAmountDecimal)
      : Number(currentPrice?.unitAmountDecimal || 0);

    return price / 100;
  }, [packages, registrationData]);

  const packagesSum = useMemo(() => {
    if (!subscriptionPackages) return 0;
    if (registrationData.privateDoctor) {
      return invoicingSubscriptionPrices;
    }
    const packages = Object.values(
      subscriptionPackages,
    ).flat() as SubscriptionPackageResponse[];

    return packages.length === 1
      ? firstDoctorPrice
      : nextDoctorPrice * (packages.length - 1) + firstDoctorPrice;
  }, [subscriptionPackages, firstDoctorPrice, nextDoctorPrice]);

  const getQuantity = (item: SubscriptionPackageResponse) => {
    const packages = Object.values(
      subscriptionPackages,
    ).flat() as SubscriptionPackageResponse[];
    const quantity = packages.filter(
      (pack) => pack.productId === item.productId,
    ).length;

    return Number(quantity);
  };

  const summary = packagesSum;

  return (
    <PaymentOrderStyled>
      <div>
        <h1>{t('clinicRegistration.payment_process.my_order')}</h1>
        <button type="button" onClick={() => setStep(0)}>
          {t(
            'clinicRegistration.payment_process.change_subscription_selection',
          )}
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {packages.map((item: SubscriptionPackageResponse) => {
            if (!item) return <></>;
            return (
              <tr key={item.productId}>
                <td>
                  <div>
                    <p>{item?.name || ''}</p>
                    <span>
                      {t('billing_period')}:{' '}
                      {registrationData.isYearlyPeriod
                        ? t('annually')
                        : t('monthly')}
                    </span>
                  </div>
                </td>
                <td>
                  {registrationData.privateDoctor ? (
                    !!invoicingSubscriptionPrices && (
                      <div
                        className={'payment-info'}
                      >{`invoicing: €${invoicingSubscriptionPrices}`}</div>
                    )
                  ) : (
                    <>
                      <div
                        className={'payment-info'}
                      >{`€${firstDoctorPrice}/${t(
                        'price_per_first',
                      )} + €${nextDoctorPrice}/${t(
                        'price_per_additional',
                      )}`}</div>
                      {!!invoicingSubscriptionPrices && (
                        <div className={'payment-info'}>{`${t(
                          'invoicing',
                        )}: €${invoicingSubscriptionPrices}`}</div>
                      )}
                    </>
                  )}
                </td>
                <td>
                  <div>
                    <div>
                      <QuantityIcon />
                      <span>{getQuantity(item)}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    €
                    {registrationData.privateDoctor
                      ? invoicingSubscriptionPrices
                      : packagesSum + invoicingSubscriptionPrices}
                  </div>
                </td>
              </tr>
            );
          })}

          <tr>
            <td colSpan={4}>
              Subtotal: €
              {registrationData.privateDoctor
                ? invoicingSubscriptionPrices
                : packagesSum + invoicingSubscriptionPrices}
            </td>
          </tr>
        </tbody>
      </table>
      <SummarySectionStyled>
        <PromoCodes />
        <p>
          {t('clinicRegistration.payment_process.discount')}: €
          {promoCode ? summary - Number(promoCode?.newAmount) / 100 : '0'}
        </p>
        {/* TODO */}
        {/* <p>{t('clinicRegistration.payment_process.taxes')}: €120</p> */}
        <h2>
          {t('clinicRegistration.payment_process.total')}: €
          {registrationData.privateDoctor
            ? invoicingSubscriptionPrices
            : promoCode
            ? Number(promoCode?.newAmount) / 100
            : summary + invoicingSubscriptionPrices}
        </h2>
      </SummarySectionStyled>
    </PaymentOrderStyled>
  );
};

export default PaymentOrder;
