import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StripePromoCodeError } from '../constants';

export const usePromoCodeError = () => {
  const { t } = useTranslation();
  const [promoCodeError, setPromoCodeError] = useState('');

  const errorMessage = useMemo(() => {
    switch (promoCodeError) {
      case StripePromoCodeError.PROMO_CODE_NOT_FOUND_OR_INACTIVE: {
        return t('errors.promo_code_error');
      }
      default: {
        return '';
      }
    }
  }, [promoCodeError, t]);

  return { errorMessage, setPromoCodeError };
};
