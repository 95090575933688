import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LeftIcon } from 'applicaiton/assets/left.svg';
import { ReactComponent as RightIcon } from 'applicaiton/assets/right.svg';

import { CounterProps } from './models';
import { StyledDiv, CounterWrapper } from './styles';

const Counter: FC<CounterProps> = ({
  value,
  id,
  handleSetQuantity,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <StyledDiv>
      <div>
        <p>{t('number_of_doctors')}</p>
      </div>
      <CounterWrapper active={false}>
        <button
          onClick={(event) => {
            event.stopPropagation();
            handleSetQuantity(value - 1);
          }}
          disabled={!value || disabled}
        >
          <LeftIcon />
        </button>
        <input
          id={id}
          type="number"
          value={value}
          onChange={(e) => handleSetQuantity(Number(e.target.value))}
          disabled={disabled}
        />
        <button
          onClick={(event) => {
            event.stopPropagation();
            handleSetQuantity(value + 1);
          }}
          disabled={disabled}
        >
          <RightIcon />
        </button>
      </CounterWrapper>
    </StyledDiv>
  );
};

export default Counter;
