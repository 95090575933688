import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  ContractualAgreement,
  Layout,
  PackageList,
  PaymentProcess,
  PersonalInfoForm,
  SetPassword,
  BillingInfo,
} from './components';
import Subtitle from './components/Subtitle';
import i18next from 'i18next';
import { useAppDispatch } from 'common/hooks/redux';
import { setIsSubscriptionsFree } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';
import { AgreementsAndPoliciesModal } from '../feature-agreements-and-policies';

interface ClinicRegistrationProps {
  step: number;
  setStep: (valeu: number) => void;
}

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY || '';
const stripePromise = loadStripe(PUBLIC_KEY);

const ClinicRegistration: FC<ClinicRegistrationProps> = ({ step, setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setIsSubscriptionsFree(true));
    };
  }, []);

  return (
    <Layout
      title={
        i18next.exists(`clinicRegistration.header.step${step}.title`)
          ? t(`clinicRegistration.header.step${step}.title`)
          : ''
      }
      subtitle={<Subtitle step={step} />}
      description={
        i18next.exists(`clinicRegistration.header.step${step}.description`)
          ? t(`clinicRegistration.header.step${step}.description`)
          : ''
      }
    >
      <Elements stripe={stripePromise}>
        {step === 0 && <PackageList setStep={setStep} />}
        {step === 1 && <ContractualAgreement setStep={setStep} />}
        {step === 2 && <PersonalInfoForm setStep={setStep} />}
        {step === 3 && <SetPassword setStep={setStep} />}
        {step === 4 && <BillingInfo setStep={setStep} />}
        {step === 5 && <PaymentProcess setStep={setStep} />}
      </Elements>
      <AgreementsAndPoliciesModal />
    </Layout>
  );
};

export default ClinicRegistration;
