import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';

import { PathNames } from 'applicaiton/routes';
import { CloseIcon, SuccessIcon } from 'applicaiton/assets';

import { SecondaryButton } from 'common/components';

import { SuccessRegistrationModalProps } from './modules';
import { StyledModal } from './styles';

const SuccessRegistrationModal: FC<SuccessRegistrationModalProps> = ({
  opened,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = () => {
    onSubmit();
    navigate(PathNames.managePlan);
  };

  return (
    <Modal open={!!opened}>
      <StyledModal>
        <CloseIcon onClick={handleSubmit} />
        <div>
          <SuccessIcon />
          <p>{t('change_plan.success_modal_applied')}</p>
          <SecondaryButton onClick={handleSubmit}>{t('ok')}</SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessRegistrationModal;
