import React, { useEffect, useMemo } from 'react';
import { Wrapper } from './styles';
import { ChatsListItem } from './ChatsListItem';
import { useChats } from '../../hooks/useChats';
import { ProfessionalChatResponseDto } from '@docbay/schemas';
import { useDynamicChats } from '../../hooks/useDynamicChats';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  setChatsReadMessagesInfo,
  setMassMessagesPatientsIds,
} from 'applicaiton/store/reducers/Chats/ChatsSlice';
import { useConversation } from '../../hooks/useConversation';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

export const ChatsList = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { setSelectedChat, selectedChat } = useChats();
  const { filteredDynamicChats } = useDynamicChats();
  const { updateChat, conversation } = useConversation();
  const { chatsReadMessagesInfo } = useAppSelector((state) => state.chats);

  const conversationSid = searchParams?.get('conversation_sid');

  const handleSelectChat = async (chat: ProfessionalChatResponseDto) => {
    setSelectedChat(chat);
    dispatch(setMassMessagesPatientsIds(null));
    await conversation?.setAllMessagesRead();
    dispatch(
      setChatsReadMessagesInfo(
        chatsReadMessagesInfo.filter(
          (item) => selectedChat?.twilioConversationSID !== item.sid,
        ),
      ),
    );

    if (!chat?.emergency) return;

    const updateData = {
      ...chat,
      emergency: false,
    };

    updateChat(chat?.twilioConversationSID!, updateData);
  };

  useEffect(() => {
    if (conversationSid && !selectedChat) {
      const currentChat = filteredDynamicChats.find(
        (item) => item.twilioConversationSID === conversationSid,
      );
      if (currentChat) {
        handleSelectChat(currentChat);
      }
    }
  }, [conversationSid, filteredDynamicChats]);

  const sortedChats = useMemo(() => {
    if (!filteredDynamicChats) return [];
    const sortedChats = filteredDynamicChats.sort((a, b) => {
      return dayjs(b.lastMessageTime! || b.updatedAt!).isAfter(
        a.lastMessageTime! || a.updatedAt!,
      )
        ? 1
        : -1;
    });

    return sortedChats;
  }, [filteredDynamicChats]);

  return (
    <Wrapper>
      {sortedChats?.map((chat) => (
        <ChatsListItem
          key={chat.id}
          chatData={chat}
          onChatSelect={handleSelectChat}
        />
      ))}
    </Wrapper>
  );
};
