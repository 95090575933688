import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClinicOwnerPackageDto } from '@docbay/schemas';

import { updateCurrentSubscription } from 'applicaiton/store/reducers/ClinicOwnerSubscription/ActionCreators';

import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import { getCurrentPeriodPrice } from 'features/feature-manage-plan/helpers/getCurrentPeriodPrice';

import ButtonGroup from '../ButtonGroup';
import PaymentOrder from '../PaymentOrder';
import SEPAInfo from '../SEPAInfo/Index';
import SuccessRegistrationModal from '../SuccessRegistrationModal';

import { PaymentProcessProps } from './models';
import { Wrapper } from './styles';
import { getUserProfile } from 'applicaiton/store/reducers/Users/ActionCreators';

const PaymentProcess: FC<PaymentProcessProps> = ({ setStep, setupIntent }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    selectedPackages,
    selectedInvoicingPackage,
    isYearlySubscription,
  } = useAppSelector((state) => state.clinicOwnerSubscription);
  const { isLoading: isUserProfileLoading } = useAppSelector(
    (state) => state.users,
  );

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const updateSubscriptionPackages = async (
    packages: ClinicOwnerPackageDto[],
    paymentMethodId: string,
  ) => {
    const response = await dispatch(
      updateCurrentSubscription({
        packages,
        paymentMethodId,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(getUserProfile());
      setShowSuccessModal(true);
    }
  };

  const handleSubmit = async () => {
    if (selectedPackages.length) {
      const currentPackage = selectedPackages[0];
      const product = currentPackage.productId;
      const price =
        getCurrentPeriodPrice(currentPackage.prices, isYearlySubscription)
          ?.id || '';
      const quantity = selectedPackages.length;
      const type = currentPackage.type;

      const featurePackages = selectedInvoicingPackage
        ? [
            {
              product: selectedInvoicingPackage.productId,
              price:
                getCurrentPeriodPrice(
                  selectedInvoicingPackage.prices,
                  isYearlySubscription,
                )?.id || '',
              quantity: 1,
              type: selectedInvoicingPackage.type,
            },
          ]
        : [];

      const packages = [
        {
          product,
          price,
          quantity,
          type,
        },
        ...featurePackages,
      ];

      if (setupIntent?.payment_method) {
        await updateSubscriptionPackages(packages, setupIntent.payment_method);
      }
    }
  };

  return (
    <Wrapper>
      {(isLoading || isUserProfileLoading) && <Loader />}
      <div>
        <section>
          <SEPAInfo setupIntent={setupIntent} />
          <PaymentOrder setStep={setStep} />
        </section>
      </div>
      <ButtonGroup>
        <PrimaryButton onClick={() => handleSubmit()} type="submit">
          {t('clinicRegistration.continue')}
        </PrimaryButton>
        <SecondaryButton
          onClick={() => setStep(2)}
          disabled={false}
          type="button"
        >
          {t('clinicRegistration.back')}
        </SecondaryButton>
      </ButtonGroup>
      {showSuccessModal && (
        <SuccessRegistrationModal
          opened={showSuccessModal}
          onSubmit={() => setStep(1)}
        />
      )}
    </Wrapper>
  );
};

export default PaymentProcess;
