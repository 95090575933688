import { FeaturesEnum } from 'applicaiton/constants/features';
import { store } from 'applicaiton/store';

export const featuresPermissions = (clinicOwnerId?: string) => {
  const profile = store.getState().users.profile;

  const checkPermission = (feature: FeaturesEnum) => {
    if (clinicOwnerId) {
      return !!profile?.featuresByClinicOwner
        .filter((item) => item.clinicOwnerId === clinicOwnerId)
        .some((item) => item.features.some((item) => item === feature));
    }
    return !!profile?.featuresByClinicOwner.some((item) =>
      item.features.some((item) => item === feature),
    );
  };

  const hasAgendaPermission = checkPermission(FeaturesEnum.Agenda);
  const hasAppointmentTypeConfigurationPermission = checkPermission(
    FeaturesEnum.AppointmentTypeConfiguration,
  );
  const hasDocboxChatsPermission = checkPermission(FeaturesEnum.DocboxChats);
  const hasDocboxSubscriptionConfigurationPermission = checkPermission(
    FeaturesEnum.DocboxSubscriptionConfiguration,
  );
  const hasDocboxSubscribesPermission = checkPermission(
    FeaturesEnum.DocboxSubscribes,
  );
  const hasStaffManagementPermission = checkPermission(
    FeaturesEnum.StaffManagement,
  );
  const hasTeleconsultationPermission = checkPermission(
    FeaturesEnum.Teleconsultation,
  );
  const hasWaitingListPermission = checkPermission(FeaturesEnum.WaitingList);
  const hasInvoicingPermission = checkPermission(FeaturesEnum.Invoicing);
  const hasPatientSMSNotificationsPermission = checkPermission(
    FeaturesEnum.PatientSMSNotifications,
  );
  const hasPatientEmailNotificationsPermission = checkPermission(
    FeaturesEnum.PatientEmailNotifications,
  );
  const hasPatientPushNotificationsPermission = checkPermission(
    FeaturesEnum.PatientPushNotifications,
  );
  const hasClinicOwnerSMSNotificationsPermission = checkPermission(
    FeaturesEnum.ClinicOwnerSMSNotifications,
  );
  const hasClinicOwnerEmailNotificationsPermission = checkPermission(
    FeaturesEnum.ClinicOwnerEmailNotifications,
  );
  const hasClinicOwnerPushNotificationsPermission = checkPermission(
    FeaturesEnum.ClinicOwnerPushNotifications,
  );
  const hasDoctorSMSNotificationsPermission = checkPermission(
    FeaturesEnum.DoctorSMSNotifications,
  );
  const hasDoctorEmailNotificationsPermission = checkPermission(
    FeaturesEnum.DoctorEmailNotifications,
  );
  const hasDoctorPushNotificationsPermission = checkPermission(
    FeaturesEnum.DoctorPushNotifications,
  );
  const hasAdminSMSNotificationsPermission = checkPermission(
    FeaturesEnum.AdminSMSNotifications,
  );
  const hasAdminEmailNotificationsPermission = checkPermission(
    FeaturesEnum.AdminEmailNotifications,
  );
  const hasAdminPushNotificationsPermission = checkPermission(
    FeaturesEnum.AdminPushNotifications,
  );
  const hasSecretarySMSNotificationsPermission = checkPermission(
    FeaturesEnum.SecretarySMSNotifications,
  );
  const hasSecretaryEmailNotificationsPermission = checkPermission(
    FeaturesEnum.SecretaryEmailNotifications,
  );
  const hasSecretaryPushNotificationsPermission = checkPermission(
    FeaturesEnum.SecretaryPushNotifications,
  );
  const hasAppointmentRequestPermission = checkPermission(
    FeaturesEnum.AppointmentRequest,
  );
  const hasPatientClinicalFilePermission = checkPermission(
    FeaturesEnum.PatientClinicalFile,
  );
  const hasDoctorWorkingHoursConfigurationPermission = checkPermission(
    FeaturesEnum.DoctorWorkingHoursConfiguration,
  );
  const hasDoctorManagementPermission = checkPermission(
    FeaturesEnum.DoctorManagement,
  );

  return {
    hasAgendaPermission,
    hasAppointmentTypeConfigurationPermission,
    hasDocboxChatsPermission,
    hasDocboxSubscriptionConfigurationPermission,
    hasDocboxSubscribesPermission,
    hasStaffManagementPermission,
    hasTeleconsultationPermission,
    hasWaitingListPermission,
    hasInvoicingPermission,
    hasPatientSMSNotificationsPermission,
    hasPatientEmailNotificationsPermission,
    hasPatientPushNotificationsPermission,
    hasClinicOwnerSMSNotificationsPermission,
    hasClinicOwnerEmailNotificationsPermission,
    hasClinicOwnerPushNotificationsPermission,
    hasDoctorSMSNotificationsPermission,
    hasDoctorEmailNotificationsPermission,
    hasDoctorPushNotificationsPermission,
    hasAdminSMSNotificationsPermission,
    hasAdminEmailNotificationsPermission,
    hasAdminPushNotificationsPermission,
    hasSecretarySMSNotificationsPermission,
    hasSecretaryEmailNotificationsPermission,
    hasSecretaryPushNotificationsPermission,
    hasAppointmentRequestPermission,
    hasPatientClinicalFilePermission,
    hasDoctorWorkingHoursConfigurationPermission,
    hasDoctorManagementPermission,
  };
};
