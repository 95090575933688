import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { getSlotsValidation } from 'common/helpers/getSlotsValidation';
import { ClinicProfessionalSubscriptionType } from 'common/types/subscriptions';

import { SubscriptionClinicT } from '../components/ProfessionalAddForm/types';

export const useSubscriptionsForm = ({
  isCompanySubscription,
}: {
  isCompanySubscription: boolean;
}) => {
  const { t } = useTranslation();

  const requiredError = t('errors.required');
  const employeesMinLengthError = t(
    'professional_profile.subscriptions.company_employee_min_error',
  );
  const employeesLengthError = t(
    'professional_profile.subscriptions.company_employee_error',
  );
  const maxLengthError = t('professional_profile.subscriptions.max_characters');

  const formSubscriptionValues = {
    clinicId: '',
    title: '',
    price: '1',
    durationInWeeks: 1,
    durationInMonth: 0,
    teleconsultationMinutes: 30,
    hours: [],
    type: ClinicProfessionalSubscriptionType.PUBLIC,
    minNumberOfEmployees: 0,
    maxNumberOfEmployees: 0,
  };

  const minNumberOfEmployeesValidation = isCompanySubscription
    ? yup.number().min(1, employeesMinLengthError).required(requiredError)
    : yup.number();
  const maxNumberOfEmployeesValidation = isCompanySubscription
    ? yup
        .number()
        .test('max', employeesLengthError, (value, context) => {
          return (value || 0) >= context.parent.minNumberOfEmployees;
        })
        .required(requiredError)
    : yup.number();

  const subscriptionSchema = yup
    .object({
      clinicId: yup.string().required(requiredError!),
      title: yup.string().max(50, maxLengthError).required(requiredError!),
      price: yup.string(),
      durationInWeeks: yup.number(),
      teleconsultationMinutes: yup.number(),
      durationInMonth: yup.number(),
      hours: getSlotsValidation(),
      type: yup.string().required(),
      minNumberOfEmployees: minNumberOfEmployeesValidation,
      maxNumberOfEmployees: maxNumberOfEmployeesValidation,
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    register,
    formState: { errors },
  } = useForm<SubscriptionClinicT>({
    resolver: yupResolver(subscriptionSchema),
    defaultValues: formSubscriptionValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    register,
  };
};
