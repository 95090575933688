import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

export const Wrapper = styled.article`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;

  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  div {
    > section {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
`;
