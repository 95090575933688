import { Period, StripePriceResponseDto } from 'applicaiton/constants/stripe';

export const getCurrentPeriodPrice = (
  prices: StripePriceResponseDto[],
  isYearlyPeriod: boolean,
) => {
  const currentPeriod = isYearlyPeriod ? Period.year : Period.month;

  return prices.find((item) => item.recurringInterval === currentPeriod);
};
